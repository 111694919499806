export function generateTableHeader(
  id: string,
  numeric: boolean,
  disablePadding: boolean,
  label: string,
  enableSorting: boolean,
) {
  return {
    id,
    numeric,
    disablePadding,
    label,
    enableSorting,
  };
}

export function createNewAppServicePlan(
  appserviceplans: string[],
  item: string[],
  FarmId: number,
) {
  const indices = appserviceplans.map((plan) => Number(plan.split('-')[1]));
  indices.sort((a, b) => a - b);

  let newIndex = 0;
  for (let i = 0; i < indices.length; i++) {
    if (indices[i] > i) {
      newIndex = i;
      break;
    }
    if (i === indices.length - 1) {
      newIndex = indices.length;
    }
  }
  const NewPlan = `appserviceplan${FarmId}-${newIndex}-${item[0]}`;
  item = [NewPlan];
  return item;
}

export function RemoveDuplicateAppserviceplans(
  appAppservicePlans: string[],
): React.SetStateAction<string[]> {
  return appAppservicePlans.filter(function (elem, index, self) {
    return index === self.indexOf(elem);
  });
}

export function GetActionLink(routeComponents: string[]) {
  const path = routeComponents.join('/');
  return `/${path}`;
}
