import {
  Badge,
  Link,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import {
  MonitoringStatistics,
  TenantHealthStatusCollection,
} from '../Model/HealthModels';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { useStyles } from '../Common/CommonStyle';
import { healthService } from '../Services/HealthService';
import { StatusIcon, TableCell } from '../Common/CommonMethods';
import CommonConstants from '../Common/CommonConstants';

const TenantInsights = ({ Components }: TenantHealthStatusCollection) => {
  const classes = useStyles();
  const initialFiredStatistics: MonitoringStatistics[] = [];
  const [firedStatistics, setFiredStatistics] = useState(
    initialFiredStatistics,
  );

  React.useEffect(() => {
    loadTenantInsight();
  }, [Components]);
  const loadTenantInsight = async () => {
    try {
      const tenantinsight = await healthService.GetTenantInsight(
        Components[0].Id,
      );
      setFiredStatistics(
        tenantinsight.Items.filter((tenant) => tenant.AlertStateId === 'fired'),
      );
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Paper sx={{ marginLeft: 2 }} elevation={0}>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableBody>
            {Components.map((component) => (
              <TableRow key={component.Url} className={classes.TableRow}>
                <TableCell>
                  <Typography>{component.Url}</Typography>
                </TableCell>
                <TableCell colSpan={4}>
                  <Link href={component.Url} target="_blank">
                    {StatusIcon(component.Status)}
                  </Link>
                </TableCell>
                <TableCell>
                  {!component.Url.includes('dss') ? (
                    <div style={{ display: 'inline-flex' }}>
                      <Tooltip title={CommonConstants.ServiceIncidents}>
                        <Badge
                          badgeContent={firedStatistics.length}
                          color="primary"
                        >
                          <NotificationsActiveIcon color="action" />
                        </Badge>
                      </Tooltip>
                    </div>
                  ) : (
                    <></>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
export default TenantInsights;
