import {
  Button,
  Card,
  LinearProgress,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useEffect } from 'react';
import { farmService } from '../FarmService';
import { Farm, Tenant } from '../Model/FarmModel';
import {
  RemoveDuplicateAppserviceplans,
  createNewAppServicePlan,
} from '../../../../Common/CommonMethods';
import { plans } from '../../../Common/Constants/ValueConstants';
import { SimpleDialog } from '../../../Common/Dialog/SimpleDialog';
import { tenantStateMapping } from '../StateMapper/StateMapper';
import { Save } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { StorageAccount } from '../../StorageAccount/Model/StorageAccountModel';
import StorageAccountConstants from '../../StorageAccount/Common/Constants';
import { GetCustomerEditActionLink } from '../../../../Common/CommonComponents';
import { CustomerV2 } from '../../Customer/Model/CustomerModel';
import { customerService } from '../../Customer/CustomerService';

const TenantTable = (props: {
  farm: Farm;
  storageaccounts: StorageAccount[];
}) => {
  const { farm, storageaccounts } = props;
  const [loading, setLoading] = React.useState(0);
  const initialTenants: Tenant[] = [];
  const [tenants, setTenants] = React.useState(initialTenants);
  const initialAppservicePlans: string[] = [];
  const [appserviceplans, setAppserviceplans] = React.useState(
    initialAppservicePlans,
  );

  const [openAppserviceDialogue, setOpenAppserviceDialogue] =
    React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(plans[0]);
  const [customers, setCustomers] = React.useState<CustomerV2[]>();

  useEffect(() => {
    loadAppservicePlans(farm.Id);
    loadTenants(farm.Id);
    loadCustomers();
  }, []);

  const loadTenants = async (FarmId: number) => {
    try {
      setLoading(loading + 1);
      await farmService.getTenantsOnFarm(FarmId).then((tenants) => {
        setTenants(tenants.Items);
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(loading - 1);
    }
  };

  const loadCustomers = async () => {
    try {
      setLoading(loading + 1);
      await customerService.getCustomers().then((customers) => {
        setCustomers(customers.Items);
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(loading - 1);
    }
  };

  const loadAppservicePlans = async (FarmId: number) => {
    try {
      setLoading(loading + 1);
      await farmService.getAppservicePlans(FarmId).then((appserviceplans) => {
        setAppserviceplans(appserviceplans);
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(loading - 1);
    }
  };

  const addItemToState = (item: string[]) => {
    const newItem = createNewAppServicePlan(appserviceplans, item, farm.Id);
    const appAppservicePlans = appserviceplans.concat(appserviceplans, newItem);
    setAppserviceplans(RemoveDuplicateAppserviceplans(appAppservicePlans));
  };

  const handleClickOpen = () => {
    setOpenAppserviceDialogue(true);
  };

  const SaveTenants = () => {
    const confirm = window.confirm('Are you sure you want to update tenants?');
    if (confirm) {
      for (const tenant of tenants) {
        const updatedtenant: Tenant = {
          FarmId: tenant.FarmId,
          ApplicationUrl: tenant.ApplicationUrl,
          StatusID: tenant.StatusID,
          DeploymentState: tenant.DeploymentState,
          SourceFarmID: tenant.SourceFarmID,
          WebApplicationPort: tenant.WebApplicationPort,
          BusinessLogicPort: tenant.BusinessLogicPort,
          StorageKeyType: tenant.StorageKeyType,
          AppDBName: tenant.AppDBName,
          ContentDBName: tenant.ContentDBName,
          AzureSQLServerName: tenant.AzureSQLServerName,
          BLDBUserName: tenant.BLDBUserName,
          BLDBUserPassword: tenant.BLDBUserPassword,
          InstallDBUserName: tenant.InstallDBUserName,
          InstallDBUserPassword: tenant.InstallDBUserPassword,
          RODBUserName: tenant.RODBUserName,
          ROUserPassword: tenant.ROUserPassword,
          WFEUserPassword: tenant.WFEUserPassword,
          KeyRotationCounter: tenant.KeyRotationCounter,
          Properties: tenant.Properties,
          AppservicePlan: tenant.AppservicePlan,
          Id: tenant.Id,
          Version: tenant.Version,
          Farm: tenant.Farm,
          InsertDate: tenant.InsertDate,
          StorageAccountName: tenant.StorageAccountName,
          OnboardedDate: tenant.OnboardedDate,
        };

        farmService
          .setTenant(updatedtenant.FarmId, tenant.Id, updatedtenant)
          .then((response) =>
            toast.success(response.Id + ' updated successfully'),
          )
          .catch((error) => toast.error('failed to save ' + error));
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name.includes('Appserviceplan-')) {
      const tenantId = name.replace('Appserviceplan-', '');
      setTenants((tenants) =>
        tenants.map((tenant) =>
          tenant.Id === tenantId
            ? { ...tenant, AppservicePlan: value }
            : tenant,
        ),
      );
    }

    if (name.includes('StorageAccountName-')) {
      if (window.confirm(StorageAccountConstants.StorageAccoutChangeWarn)) {
        const tenantId = name.replace('StorageAccountName-', '');
        setTenants((tenants) =>
          tenants.map((tenant) =>
            tenant.Id === tenantId
              ? { ...tenant, StorageAccountName: value }
              : tenant,
          ),
        );
      }
    }
  };

  const handleClose = (value: string) => {
    setOpenAppserviceDialogue(false);
    if ('Not Selected' !== value) {
      setSelectedValue(value);
      const appserviceplan: string[] = [];
      appserviceplan.push(value);
      addItemToState(appserviceplan);
    }
  };

  const getTenantSubDomain = (tenantId: string) => {
    return customers?.find(
      (x) => x.Id?.toLocaleLowerCase() == tenantId.toLocaleLowerCase(),
    )?.Subdomain;
  };

  return (
    <Card elevation={2} sx={{ margin: 2 }}>
      <TableContainer style={{ maxHeight: 450 }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Customer Id</TableCell>
              <TableCell>Subdomain</TableCell>
              <TableCell>State</TableCell>
              <TableCell>Appservice Plan</TableCell>
              <TableCell>Storage Account</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading > 0 ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <LinearProgress />
                </TableCell>
              </TableRow>
            ) : (
              tenants.map((tenant) => (
                <TableRow key={tenant.Id}>
                  <TableCell>
                    <GetCustomerEditActionLink id={tenant.Id} />
                  </TableCell>
                  <TableCell>{getTenantSubDomain(tenant.Id)}</TableCell>
                  <TableCell>{tenantStateMapping[tenant.StatusID]}</TableCell>
                  <TableCell>
                    <Select
                      name={`Appserviceplan-${tenant.Id}`}
                      style={{ width: '300px' }}
                      value={tenant.AppservicePlan}
                      onChange={(e) =>
                        handleChange(e as React.ChangeEvent<HTMLInputElement>)
                      }
                    >
                      <MenuItem
                        value={'Create New Appservice Plan'}
                        onClick={handleClickOpen}
                      >
                        Create New Appservice Plan
                      </MenuItem>
                      {appserviceplans.map((plan) => {
                        return (
                          <MenuItem key={plan} value={plan}>
                            {plan}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {openAppserviceDialogue &&
                      renderAppservicePlanDialogue(
                        handleClose,
                        openAppserviceDialogue,
                        selectedValue,
                      )}
                  </TableCell>
                  <TableCell>
                    <Select
                      name={`StorageAccountName-${tenant.Id}`}
                      style={{ width: '300px' }}
                      value={tenant.StorageAccountName}
                      onChange={(e) =>
                        handleChange(e as React.ChangeEvent<HTMLInputElement>)
                      }
                    >
                      {storageaccounts.map((sa) => {
                        return (
                          <MenuItem key={sa.Name} value={sa.Name}>
                            {sa.Name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </TableCell>
                </TableRow>
              ))
            )}
            <TableRow>
              <TableCell colSpan={5}>
                <Button
                  disabled={tenants.length <= 0}
                  variant="contained"
                  onClick={SaveTenants}
                  startIcon={<Save />}
                  sx={{ float: 'right' }}
                >
                  Save
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default TenantTable;

export function renderAppservicePlanDialogue(
  handleClose: (value: string) => void,
  openAppserviceDialogue: boolean,
  selectedValue: string,
): React.ReactNode {
  return (
    <SimpleDialog
      onClose={handleClose}
      open={openAppserviceDialogue}
      selectedValue={selectedValue}
    />
  );
}
