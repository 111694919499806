import React from 'react';
import {
  Backdrop,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { useHistory, useParams } from 'react-router';
import { farmService } from '../FarmService';
import { toast } from 'react-toastify';

const useStyle = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  padding: {
    padding: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(2),
    float: 'right',
  },
  cardactions: {
    float: 'right',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

interface UriParams {
  FarmId: string;
}

const DeleteForm = () => {
  const classes = useStyle();
  const params = useParams<UriParams>();
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const handleCancel = () => {
    history.push('/farm');
  };
  const handleDelete = async () => {
    setLoading(true);
    const deletedfarm = await farmService.deleteFarm(+params.FarmId);
    toast.success(
      `Running Delete On ${deletedfarm.DisplayName}(${deletedfarm.Id})`,
    );
    setLoading(false);
    history.push('/farm');
  };
  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item md={6}>
        <Card className={classes.padding}>
          <Backdrop open={loading}>
            <CircularProgress className={classes.backdrop} color="inherit" />
          </Backdrop>
          <CardHeader title="Delete Farm"></CardHeader>
          <CardContent>
            Are you sure you want to delete this farm : {params.FarmId} ?
          </CardContent>
          <CardActions className={classes.cardactions}>
            <Button
              className={classes.button}
              variant="contained"
              startIcon={<CheckIcon />}
              type="submit"
              onClick={handleDelete}
            >
              Yes
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              startIcon={<ClearIcon />}
              type="submit"
              onClick={handleCancel}
            >
              No
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};

export default DeleteForm;
