import React, { Component } from 'react';
import { OnCallUser } from '../Model/MonitoringModel';
import UserMenu from './UserMenu';
import userHelper from '../../../../Helpers/UserHelper';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import {
  Typography,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  AccordionActions,
  Snackbar,
} from '@mui/material';
import { idToColor } from '../../../../Helpers/UtilityMethods';

interface Props extends WithStyles<typeof styles> {
  loading: boolean;
  onCallUsers: OnCallUser[];
  removeUserFromArray: (userId: string) => void;
}

interface OnCallUsersState {
  loading: boolean;
  expanded: string;
  showSnackbar: boolean;
  snackbarMessage: string;
}

const styles = (theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
      padding: theme.spacing(0, 0, 0, 3),
    },
    expansionPanelAction: {
      padding: 0,
    },
    avatar: {
      width: 60,
      height: 60,
      marginLeft: '15%',
      marginTop: '3%',
    },
    column: {
      flexBasis: '33.33%',
    },
    helper: {
      borderLeft: `2px solid ${theme.palette.divider}`,
      marginLeft: -12,
      padding: theme.spacing(0, 0, 0, 3),
    },
  });

class OnCallUsersList extends Component<Props, OnCallUsersState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: props.loading,
      expanded: 'false',
      showSnackbar: false,
      snackbarMessage: '',
    };
  }

  handlePanelSelectionChange = (panel: string) => (isExpanded: string) => {
    this.setState({ expanded: isExpanded ? panel : 'false' });
  };

  getNameFromEmail = (emailId: string) => {
    return userHelper.getNameFromId(emailId);
  };

  getInitials = (emailId: string) => {
    return userHelper.GetInitialsFromId(emailId);
  };

  handleSnackbarClose = () => {
    this.setState({ showSnackbar: false });
  };

  removeUserFromArray = (userId: string) => {
    this.props.removeUserFromArray(userId);
    this.setState({
      snackbarMessage: 'User is removed from on call users list',
    });
    this.setState({ showSnackbar: true });
  };

  render() {
    return (
      <div>
        {this.props.loading ? (
          <div>
            <br />
            <Typography className={this.props.classes.secondaryHeading}>
              Waiting.... will load once onCallOfTheWeek is loaded...
            </Typography>
            <LinearProgress />
          </div>
        ) : (
          <div>
            <Typography variant="subtitle2" gutterBottom>
              On call users in system:
            </Typography>
            {this.props.onCallUsers.map((user: OnCallUser) => (
              <Accordion
                key={user.Id}
                expanded={this.state.expanded === user.Id}
                onChange={() => this.handlePanelSelectionChange(user.Id)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={this.props.classes.heading}>
                    {user.Name}
                  </Typography>
                  <Typography className={this.props.classes.secondaryHeading}>
                    {this.getNameFromEmail(user.EmailAddress)}
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <div className={this.props.classes.column}>
                    <Avatar
                      className={this.props.classes.avatar}
                      style={{ backgroundColor: idToColor(user.Id) }}
                    >
                      {this.getInitials(user.EmailAddress)}
                    </Avatar>
                  </div>
                  <div
                    className={`${this.props.classes.column} ${this.props.classes.helper}`}
                  >
                    <Typography variant="caption">
                      Can be reached at:
                      <br />
                    </Typography>
                    <Typography color="textSecondary">
                      {user.PhoneNumber}
                      <br />
                      {user.EmailAddress}
                    </Typography>
                  </div>
                </AccordionDetails>

                <AccordionActions
                  className={this.props.classes.expansionPanelAction}
                >
                  <UserMenu
                    onCallUser={user}
                    removeUser={(userId: string) =>
                      this.removeUserFromArray(userId)
                    }
                  />
                </AccordionActions>
              </Accordion>
            ))}
          </div>
        )}
        <Snackbar
          open={this.state.showSnackbar}
          onClose={this.handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.state.snackbarMessage}</span>}
        />
      </div>
    );
  }
}

export default withStyles(styles)(OnCallUsersList);
