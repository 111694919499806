import 'react-toastify/dist/ReactToastify.css';
import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import {
  CssBaseline,
  Drawer,
  IconButton,
  Divider,
  List,
  Tooltip,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Stack,
  Collapse,
  Menu,
  MenuItem,
  useTheme,
  ClickAwayListener,
} from '@mui/material';
import { ToastContainer } from 'react-toastify';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PortalBar from './PortalBar';
import MonitoringHome from '../Monitoring/MonitoringHome';
import OncalldrillHome from '../Oncalldrill/OncalldrillHome';
import MetricsHome from '../Metrics/MetricsHome';
import CustomerHomePage from '../Customer/Home/CustomerHomePage';
import LicenseHomePage from '../../../LicenseModule/Dashboard/Views/HomePage';
import PurchaseOrdersPage from '../../../LicenseModule/PurchaseOrders/Views/PurchaseOrdersPage';
import ManageLicenses from '../../../LicenseModule/Dashboard/Views/LicenseManagementPage';
import LicenseLogPage from '../../../LicenseModule/LicenseLogs/Views/LicenseLogPage';
import ManageModuleLicenses from '../../../LicenseModule/Dashboard/Views/ModuleLicenseManagementPage';
import Home from '../Home/Home';
import CollaborationRelay from '../Customer/Components/CollaborationRelay';
import TriggerHome from '../Trigger/TriggerHome';
import TrustedClientsHome from '../TrustedClients/Home/TrustedClientsHome';
import DssSiteHome from '../DssSite/DssSiteHome';
import NotFoundPage from './Global/NotFound';
import { globalService } from './Global/GlobalService';
import { PermissionsContext } from '../UIContainer/PermissionsWrapper';
import ServicesHome from '../Services/ServicesHome';
import ClientSecreteHome from '../ClientSecrete/Home/ClientSecreteHome';
import EditCustomerPage from '../Customer/Components/EditCustomerPage';
import FarmTable from '../ManageFarms/Home/FarmTable';
import FarmTopology from '../Topology/Topology';
import FarmForm from '../ManageFarms/Components/FarmForm';
import EditForm from '../ManageFarms/Components/EditFarm';
import DeleteFarm from '../ManageFarms/Components/DeleteFarm';
import TenantRegistration from '../Tenant/TenantRegistration';
import TenantMove from '../Tenant/TenantMove';
import RemoveCustomer from '../Customer/Components/RemoveCustomer';
import DeployFarm from '../ManageFarms/Components/DeployFarm';
import DeployGlobalComponents from '../AzureDevOps/Components/DeployGlobalComponents';
import { CustomRoute } from './Global/RouteProps';
import Menus, { MenuOptions } from '../../../Routes/Routes';
import * as Icons from '@mui/icons-material';
import {
  hasAccessToModule,
  hasAccessToPage,
} from '../../../Permission/permissionHandler';
import { TourProvider } from '@reactour/tour';
import CloneTenant from '../Customer/Components/CloneTenant';
import CreateCustomerPage from '../Customer/Components/CreateCustomerPage';
import { Dictionary } from '../../Common/CommonModel';
import { Permissions } from './Global/GlobalState';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBarSpacer: {
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      paddingBottom: 0,
      height: '100vh',
      overflow: 'auto',
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    drawerPaper: {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      position: 'relative',
      overflowX: 'hidden',
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(7),
      },
    },
    alertTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      maxheight: '35px',
    },
    p: {
      padding: '20px',
    },
  }),
);

export default function Wrapper() {
  const classes = useStyles();
  const [isDrawerOpen, setDrawerState] = useState(false);
  const [isNotificationDrawerOpen, setNotificationDrawerState] =
    useState(false);
  const [isProduction, setIsProduction] = useState(false);

  const permissions = useContext(PermissionsContext);
  const [open, setOpen] = React.useState<string | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const theme = useTheme();

  const handleOnHoverClick = (
    event: React.MouseEvent<HTMLElement>,
    key: string,
  ) => {
    if (isDrawerOpen) {
      setOpen(open === key ? null : key);
    } else {
      setAnchorEl(event.currentTarget);
      setOpen(key);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    globalService.getCurrentUserPermissions().then((Permissions) => {
      const permissions = JSON.stringify(Permissions);
      localStorage.setItem('userPermissions', permissions);
    });
    isProductionEnv();
  }, []);

  const handleDrawerOpen = () => {
    setDrawerState(true);
  };

  const handleNotificationDrawer = () => {
    setNotificationDrawerState(!isNotificationDrawerOpen);
  };

  const handleDrawerClose = () => {
    setDrawerState(false);
    setOpen(null);
  };

  const isProductionEnv = () => {
    globalService.getPortalConfig().then((info) => {
      info.Environment.toUpperCase() === 'DEV'
        ? setIsProduction(false)
        : setIsProduction(true);
    });
  };

  const loadFirstSubMenuLink = (
    permissions: Dictionary<Permissions>,
    menu: MenuOptions,
    isProduction: boolean,
  ) => {
    const submenu = menu.SubMenu?.find((sm) =>
      hasAccessToModule(permissions, sm.validationFunc, isProduction),
    );

    if (submenu != undefined) {
      return submenu.link ?? '/';
    }
  };

  // const AlertNotification = (payload: RunbookPayload) => {
  //   const name = payload.Name;
  //   const status = payload.Status;
  //   const StatusModifiedTime = payload.LastStatusModifiedTime;
  //   if (status === 'Completed') {
  //     return (
  //       <Alert severity="success">
  //         <AlertTitle>Runbook Job Complete</AlertTitle>
  //         <div className={classes.alertTitle}>
  //           "{name}" — <strong>Check For Logs</strong>
  //           <p className={classes.p}>{StatusModifiedTime}</p>
  //         </div>
  //       </Alert>
  //     );
  //   } else if (status === 'Started') {
  //     return (
  //       <Alert severity="info">
  //         <AlertTitle>Runbook Job Started Now</AlertTitle>
  //         <div className={classes.alertTitle}>
  //           Runbook "{name}" Job Is In Progress
  //           <p className={classes.p}>{StatusModifiedTime}</p>
  //         </div>
  //       </Alert>
  //     );
  //   } else if (status === 'Failed') {
  //     return (
  //       <Alert severity="error">
  //         <AlertTitle>Runbook Job Failed</AlertTitle>
  //         <div className={classes.alertTitle}>
  //           Runbook "{name}" Failed
  //           <p className={classes.p}>{StatusModifiedTime}</p>
  //         </div>
  //       </Alert>
  //     );
  //   } else {
  //     return (
  //       <Alert severity="error">
  //         <AlertTitle>Runbook Job Not Running or Failed</AlertTitle>
  //         <div className={classes.alertTitle}>
  //           Runbook "{name}" Failed — <strong>Check For Logs</strong>
  //           <p className={classes.p}>{StatusModifiedTime}</p>
  //         </div>
  //       </Alert>
  //     );
  //   }
  // };

  const routes: CustomRoute[] = [
    {
      path: '/',
      exact: true,
      component: Home,
      name: 'Proarc Online Management',
    },
    {
      path: '/monitoring',
      component: MonitoringHome,
      name: 'Monitoring & Notification',
    },
    {
      path: '/customer/:customerId/oncalldrill',
      component: OncalldrillHome,
      name: 'On Call Drill',
    },
    {
      path: '/farm/:Subscription/deploymentcenter',
      component: DeployGlobalComponents,
      name: 'Deployment Center',
    },
    {
      path: '/farm',
      exact: true,
      component: FarmTable,
      name: 'Manage Farms',
    },
    { path: '/farm/new', component: FarmForm, name: 'New Farm' },
    {
      path: '/farm/:FarmId/topology',
      component: FarmTopology,
      name: 'Farm Topology',
    },
    { path: '/farm/:FarmId/edit', component: EditForm, name: 'Edit Farm' },
    {
      path: '/farm/delete/:FarmId',
      component: DeleteFarm,
      name: 'Delete Farm',
    },
    {
      path: '/farm/deploy/:FarmId',
      component: DeployFarm,
      name: 'Deploy Farm',
    },
    {
      path: '/storageaccount/:Operation/name/:Name',
      component: hasAccessToPage(
        permissions,
        'StorageAccountOpsPage',
        isProduction,
      ),
      name: 'Storage Account Operation',
    },
    {
      path: '/storageaccount/:Operation',
      component: hasAccessToPage(
        permissions,
        'StorageAccountOpsPage',
        isProduction,
      ),
      name: 'Storage Account Operation',
    },
    {
      path: '/storageaccount',
      component: hasAccessToPage(
        permissions,
        'StorageAccountPage',
        isProduction,
      ),
      name: 'Storage Account',
    },
    {
      path: '/accesscontrol',
      component: hasAccessToPage(
        permissions,
        'AccessControlPage',
        isProduction,
      ),
      name: 'Access Control',
    },
    {
      path: '/keyvault/:Operation/key/:KeyName',
      component: hasAccessToPage(permissions, 'SecureKeyOpsPage', isProduction),
      name: 'Secure Key Operation',
    },
    {
      path: '/keyvault/:Operation',
      component: hasAccessToPage(permissions, 'SecureKeyOpsPage', isProduction),
      name: 'Secure Key Operation',
    },
    {
      path: '/keyvault',
      component: hasAccessToPage(permissions, 'KeyVaultPage', isProduction),
      name: 'Manage Secure key',
    },
    {
      path: '/emailadministration/sendemail',
      component: hasAccessToPage(permissions, 'EmailSendPage', isProduction),
      name: 'Send Email',
    },
    {
      path: '/emailadministration/emailtemplate/create',
      component: hasAccessToPage(
        permissions,
        'CreateTemplatePage',
        isProduction,
      ),
      name: 'Create Template',
    },
    {
      path: '/emailadministration/:name/:product',
      component: hasAccessToPage(permissions, 'EditTemplatePage', isProduction),
      name: 'Edit Template',
    },
    {
      path: '/emailadministration',
      component: hasAccessToPage(
        permissions,
        'EmailTemplatePage',
        isProduction,
      ),
      name: 'Email',
    },
    {
      path: '/customer',
      exact: true,
      component: CustomerHomePage,
      name: 'Customers',
    },
    {
      path: '/customer/:customerId/modules',
      component: hasAccessToPage(permissions, 'ModulePage', isProduction),
      name: 'Modules',
    },
    {
      path: '/customer/:customerId/relay',
      component: CollaborationRelay,
      name: 'Collaboration Relay',
    },
    { path: '/metrics', component: MetricsHome, name: 'Metrics' },
    { path: '/licenses', component: LicenseHomePage, name: 'License' },
    {
      path: '/license/manage/:tenantId',
      component: ManageLicenses,
      name: 'Manage Licenses',
    },
    {
      path: '/license/module/:tenantId',
      component: ManageModuleLicenses,
      name: 'Manage Module Licenses',
    },
    {
      path: '/purchaseorders/:tenantId',
      component: PurchaseOrdersPage,
      name: 'Purchase Order',
    },
    {
      path: '/logs/:tenantId',
      component: LicenseLogPage,
      name: 'License Log Page',
    },
    {
      path: '/services',
      exact: true,
      component: ServicesHome,
      name: 'Services',
    },
    {
      path: '/audits',
      component: hasAccessToPage(permissions, 'AuditPage', isProduction),
      name: 'Audit Trail',
    },
    {
      path: '/customer/:customerId/trigger',
      component: TriggerHome,
      name: 'Trigger',
    },
    {
      path: '/customer/:customerId/trustedclients',
      component: TrustedClientsHome,
      name: 'TrustedClients',
    },
    {
      path: '/customer/:customerId/manageclientsecret',
      component: ClientSecreteHome,
      name: 'Api Key',
    },
    {
      path: '/customer/:customerId/clone',
      component: CloneTenant,
      name: 'Clone Tenant',
    },
    {
      path: '/customer/create',
      component: CreateCustomerPage,
      name: 'Create Customer',
    },
    {
      path: '/customer/:customerId/edit',
      component: EditCustomerPage,
      name: 'Edit Customer',
    },
    {
      path: '/customer/:customerId/createtenant',
      component: TenantRegistration,
      name: 'Tenant Registration',
    },
    {
      path: '/customer/:customerId/movetenant',
      component: TenantMove,
      name: 'Move Tenant',
    },
    {
      path: '/customer/:customerId/delete',
      component: RemoveCustomer,
      name: 'Remove Customer',
    },
    {
      path: '/customer/:customerId/restart',
      component: hasAccessToPage(
        permissions,
        'TenantRestartPage',
        isProduction,
      ),
      name: 'Tenant Restart',
    },
    {
      path: '/customer/trustedclients/:customerId/Client/:ClientID',
      component: TrustedClientsHome,
      name: 'Trusted Clients',
    },
    {
      path: '/customer/:customerId/sites',
      component: DssSiteHome,
      name: 'Dss Sites',
    },
    { path: '*', component: NotFoundPage, name: 'Not Found' },
  ];

  return (
    <Router>
      <TourProvider
        steps={[]}
        disableKeyboardNavigation={['esc']}
        defaultOpen={false}
        position={'center'}
        styles={{
          popover: (base) => ({
            ...base,
            borderRadius: 10,
            background: theme.palette.mode === 'dark' ? '#121415' : '#fff',
            color: theme.palette.mode === 'dark' ? '#fafafa' : '#212B36',
            fontSize: '1rem',
          }),
        }}
      >
        <div className={classes.root}>
          <CssBaseline />
          <PortalBar
            notificationClick={handleNotificationDrawer}
            drawerOpen={isDrawerOpen}
            clickOpen={handleDrawerOpen}
            routes={routes}
          />
          {/* <RunbookHub setRunbookData={setRunbookData} /> */}
          <Drawer
            anchor="right"
            open={isNotificationDrawerOpen}
            sx={{
              width: 500,
              flexShrink: 0,
              padding: 20,
              '& .MuiDrawer-paper': {
                width: 500,
              },
            }}
          >
            <div className={classes.toolbarIcon}>
              <IconButton onClick={handleNotificationDrawer} size="large">
                <ChevronLeftIcon />
              </IconButton>
            </div>

            <Divider />
            <Stack sx={{ width: '100%' }} spacing={1}></Stack>
          </Drawer>
          <Drawer
            variant="permanent"
            classes={{
              paper: !isDrawerOpen
                ? classes.drawerPaperClose
                : classes.drawerPaper,
            }}
            open={isDrawerOpen}
          >
            <div className={classes.toolbarIcon}>
              <IconButton onClick={handleDrawerClose} size="large">
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <List>
              {Menus.map(
                (menu) =>
                  hasAccessToModule(
                    permissions,
                    menu.validationFunc,
                    isProduction,
                  ) && (
                    <div key={menu.key}>
                      {menu.link ? (
                        <ListItemButton
                          key={menu.key}
                          component={Link}
                          to={menu.link}
                        >
                          <ListItemIcon>
                            <menu.icon sx={{ color: '#0078d4' }} />
                          </ListItemIcon>
                          <ListItemText primary={menu.name} />
                        </ListItemButton>
                      ) : (
                        <ListItemButton
                          key={menu.key}
                          component={Link as React.ElementType}
                          to={loadFirstSubMenuLink(
                            permissions,
                            menu,
                            isProduction,
                          )}
                          onMouseOver={(
                            event: React.MouseEvent<HTMLElement, MouseEvent>,
                          ) => handleOnHoverClick(event, menu.key)}
                        >
                          <ListItemIcon>
                            <menu.icon sx={{ color: '#0078d4' }} />
                          </ListItemIcon>
                          <ListItemText primary={menu.name} />
                          {!menu.link &&
                            (open === menu.key ? (
                              <Icons.ExpandLess />
                            ) : (
                              <Icons.ExpandMore />
                            ))}
                        </ListItemButton>
                      )}

                      {isDrawerOpen && menu.SubMenu && (
                        <Collapse
                          in={open === menu.key}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            {menu.SubMenu.map(
                              (subMenu) =>
                                hasAccessToModule(
                                  permissions,
                                  subMenu.validationFunc,
                                  isProduction,
                                ) && (
                                  <ListItemButton
                                    key={subMenu.key}
                                    component={Link as React.ElementType}
                                    to={subMenu.link ? subMenu.link : '#'}
                                    sx={{ pl: 4 }}
                                  >
                                    <Tooltip title={subMenu.tooltip}>
                                      <ListItemIcon>
                                        <subMenu.icon
                                          sx={{ color: '#0078d4' }}
                                          fontSize="small"
                                        />
                                      </ListItemIcon>
                                    </Tooltip>
                                    <ListItemText primary={subMenu.name} />
                                  </ListItemButton>
                                ),
                            )}
                          </List>
                        </Collapse>
                      )}
                      {!isDrawerOpen && menu.SubMenu && (
                        <Tooltip title={menu.tooltip}>
                          <ClickAwayListener
                            mouseEvent="onMouseDown"
                            onClickAway={handleClose}
                          >
                            <Menu
                              anchorEl={anchorEl}
                              open={menuOpen && open === menu.key}
                              onClose={handleClose}
                              onMouseLeave={handleClose}
                              elevation={0}
                              style={{
                                overflow: 'visible',
                                filter:
                                  'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                marginLeft: '40px',
                              }}
                              transformOrigin={{
                                horizontal: 'right',
                                vertical: 'top',
                              }}
                              anchorOrigin={{
                                horizontal: 'right',
                                vertical: 'bottom',
                              }}
                            >
                              {menu.SubMenu.map(
                                (subMenu) =>
                                  hasAccessToModule(
                                    permissions,
                                    subMenu.validationFunc,
                                    isProduction,
                                  ) && (
                                    <MenuItem
                                      key={subMenu.key}
                                      component={Link as React.ElementType}
                                      to={subMenu.link ? subMenu.link : '#'}
                                      sx={{ pl: 4 }}
                                      onClick={handleClose}
                                    >
                                      <ListItemIcon>
                                        <subMenu.icon
                                          sx={{ color: '#0078d4' }}
                                          fontSize="small"
                                        />
                                      </ListItemIcon>
                                      <ListItemText
                                        primary={subMenu.name}
                                        sx={{ fontSize: 'small' }}
                                      />
                                    </MenuItem>
                                  ),
                              )}
                            </Menu>
                          </ClickAwayListener>
                        </Tooltip>
                      )}
                    </div>
                  ),
              )}
            </List>
          </Drawer>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <div>
              <Switch>
                {routes.map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    component={route.component}
                    exact={route.exact || false}
                  />
                ))}
              </Switch>
            </div>
          </main>
          <ToastContainer pauseOnFocusLoss={false} position="bottom-left" />
        </div>
      </TourProvider>
    </Router>
  );
}
