import {
  Card,
  Paper,
  TextField,
  MenuItem,
  Typography,
  Checkbox,
  Divider,
  Grid,
} from '@mui/material';
import React, { FC, useState } from 'react';
import { CheckedItemsState } from '../Model/AzureDevOpsModel';
import { azureDevOpsService } from '../AzureDevOpsService';
import { farmService } from '../../Farm/FarmService';
import { Farm } from '../../Farm/Model/FarmModel';
import {
  GlobalComponent,
  ReleaseRequest,
} from '../../ManageFarms/Model/FarmModel';
import { toast } from 'react-toastify';
import FarmConstants from '../../ManageFarms/Model/FarmConstants';
import {
  DeploymentFooter,
  DeploymentTypeComponent,
  GlobalDeploymentComponent,
} from '../Common/CommonMethod';
import { Loader } from '../../../../Common/Components/Loader';
import { useStyles } from '../../../../Common/Styles/WraperStyle';
import { useStyles as commonStyles } from '../../../../Common/Styles/FarmStyle';
import { BranchCardProps, BranchSelectorProps } from './DeploymentProps';

export default function DeployGlobalComponents() {
  const classes = useStyles();
  const commonClass = commonStyles();
  const initialfarms: Farm[] = [];
  const [farms, setFarms] = useState(initialfarms);
  const [checkedItems, setCheckedItems] = useState<CheckedItemsState>({});

  const [branches, setbranches] = useState<string[]>([]);
  const [selectedBranch, setSelectedBranch] = useState('refs/heads/master');
  const [selectedDtsBranch, setSelectedDtsBranch] =
    useState('refs/heads/master');
  const [dtsBranches, setDTSBranches] = useState<string[]>([]);
  const [formatconverter, setFormatConverter] = useState(false);
  const [appGateway, setAppGateway] = useState(false);
  const [webviewer, setWebviewer] = useState(false);
  const [indexingsearch, setIndexingSearch] = useState(false);
  const [disasterrecovery, setDisasterrecovery] = useState(false);
  const [rollback, setRollback] = useState(false);
  const [DeployOnly, setDeployOnly] = useState(false);
  const [DeployDTS, setDeployDTS] = useState(false);
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    loadInitData();
  }, []);

  const loadInitData = async () => {
    await loadDtsBuilds();
    await loadBranches();
    await loadFarms();
  };

  const loadDtsBuilds = async () => {
    try {
      setLoading(true);
      const dtsbranches = await azureDevOpsService.getDtsBranches();
      setDTSBranches(dtsbranches.Items);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const loadBranches = async () => {
    try {
      const branches = await azureDevOpsService.getBranches();
      setbranches(branches.Items);
    } catch (error) {
      console.log(error);
    }
  };

  const loadFarms = async () => {
    try {
      const farmsdata = await farmService.getFarms();
      const farms = farmsdata.Items.filter(function (e) {
        return e.Type === 'ProArc Online';
      });
      setFarms(farms);
    } catch (error) {
      console.log(error);
    }
  };

  function handleCheckbox(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.name === 'FarmatConverter') {
      setFormatConverter(event.target.checked);
    } else if (event.target.name === 'DTS') {
      setDeployDTS(event.target.checked);
    }
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };

  function handleFieldChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    if (name === 'SelectedBranch') {
      setSelectedBranch(value);
    } else if (name === 'SelectedDtsBuild') {
      setSelectedDtsBranch(value);
    }
  }

  const RenderCheckbox = (props: {
    name: string;
    checked: boolean;
    label: string;
    handler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  }) => {
    return (
      <div
        style={{
          display: 'block',
          marginRight: '20px',
        }}
      >
        <Checkbox
          checked={props.checked}
          color="primary"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          name={props.name}
          onChange={props.handler}
        />
        <label>{props.label}</label>
      </div>
    );
  };

  const DeployGlobalService = async () => {
    await deployGlobalComponent();
    await deployDTS();

    async function deployDTS() {
      const release: ReleaseRequest = {};
      if (DeployDTS) {
        release.deployDTS = DeployDTS;
        release.dtsBranch = selectedDtsBranch;
        await CreateRelease(release);
      }
    }

    async function deployGlobalComponent() {
      const release: ReleaseRequest = {};

      release.rollback = rollback;
      release.disasterRecovery = disasterrecovery;
      release.branch = selectedBranch;

      let farmids: number[] = [];

      switch (true) {
        case webviewer:
          release.GlobalComponent = GlobalComponent.WEBVIEW;
          await CreateRelease(release);
          break;
        case formatconverter:
          release.GlobalComponent = GlobalComponent.FORMATCONVERTER;
          await CreateRelease(release);
          break;
        case appGateway:
          release.GlobalComponent = GlobalComponent.APPGATEWAY;
          await CreateRelease(release);
          break;
        case indexingsearch:
          release.GlobalComponent = GlobalComponent.INDEXINGSEARCH;
          farmids = Object.keys(checkedItems)
            .filter((key) => checkedItems[key])
            .map(Number);
          if (farmids.length < 1) {
            toast.info(
              'select atleast one of the farm to deploy indexing search',
            );
            break;
          }
          for (const farmId of farmids) {
            await CreateRelease({ ...release, farmId });
          }
          break;
        default:
          if (!DeployDTS) {
            toast.info('You need to select atleast one of the Global Services');
          }
          return;
      }
    }
  };

  const CreateRelease = async (Release: ReleaseRequest) => {
    try {
      azureDevOpsService.CreateRelease(Release);
      toast.success(FarmConstants.DeploymentQueued);
    } catch (error) {
      toast.error(FarmConstants.DeploymentQueueFailed);
    }
  };

  const DtsBranchSelector: FC<BranchSelectorProps> = ({
    branches,
    selectedBranch,
    handleFieldChange,
  }) => (
    <div className={commonClass.spanContainer}>
      <span className={commonClass.span}>DTS Branch</span>
      <TextField
        className={commonClass.textField}
        value={selectedBranch}
        placeholder="Dts build"
        name="SelectedDtsBuild"
        onChange={handleFieldChange}
        select
      >
        {branches.map((branch) => (
          <MenuItem key={branch} value={branch}>
            {branch}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );

  const PlatformBranchSelector: FC<BranchSelectorProps> = ({
    branches,
    selectedBranch,
    handleFieldChange,
  }) => (
    <div className={commonClass.spanContainer}>
      <span className={commonClass.span}>Platform Branch</span>
      <TextField
        className={commonClass.textField}
        value={selectedBranch}
        name="SelectedBranch"
        onChange={handleFieldChange}
        select
      >
        {branches.map((branch) => (
          <MenuItem key={branch} value={branch}>
            {branch}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );

  const BranchCard: FC<BranchCardProps> = ({
    dtsBranches,
    branches,
    selectedDtsBranch,
    selectedBranch,
    handleFieldChange,
  }) => (
    <div className={commonClass.outerContainer}>
      <Card elevation={0} className={commonClass.card}>
        <h3>Branches</h3>
        {dtsBranches && DeployDTS && (
          <DtsBranchSelector
            branches={dtsBranches}
            selectedBranch={selectedDtsBranch}
            handleFieldChange={handleFieldChange}
          />
        )}
        {branches &&
          (webviewer || indexingsearch || appGateway || formatconverter) && (
            <PlatformBranchSelector
              branches={branches}
              selectedBranch={selectedBranch}
              handleFieldChange={handleFieldChange}
            />
          )}
      </Card>
    </div>
  );

  return (
    <>
      <Loader loading={loading} />
      <div>
        <Paper>
          <Typography variant="h5" className={classes.title}>
            Deployment Center
          </Typography>
          <Divider className={classes.divider} />
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div className={commonClass.outerContainer}>
                <Card elevation={0} className={commonClass.card}>
                  <h3>Global Services</h3>
                  <div className={commonClass.checkBoxContainer}>
                    <GlobalDeploymentComponent
                      webviewer={webviewer}
                      appgateway={appGateway}
                      formatconverter={formatconverter}
                      indexingsearch={indexingsearch}
                      handleCheckbox={handleCheckbox}
                      setFormatConverter={setFormatConverter}
                      setAppGateway={setAppGateway}
                      setWebViewer={setWebviewer}
                      setIndexingSearch={setIndexingSearch}
                    />
                    <RenderCheckbox
                      checked={DeployDTS}
                      label="DTS"
                      name="DTS"
                      key="DTS"
                      handler={handleCheckbox}
                    />
                  </div>
                </Card>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div className={commonClass.outerContainer}>
                <Card elevation={0} className={commonClass.card}>
                  <h3>Deployment Type(Leave empty for default)</h3>
                  <div className={commonClass.checkBoxContainer}>
                    <DeploymentTypeComponent
                      disasterrecovery={disasterrecovery}
                      rollback={rollback}
                      DeployOnly={DeployOnly}
                      handleCheckbox={handleCheckbox}
                      setDeployOnly={setDeployOnly}
                      setRollback={setRollback}
                      setDisasterrecovery={setDisasterrecovery}
                      displayOptions={{
                        deployOnly: false,
                        rollback: true,
                        disasterRecovery: true,
                      }}
                    />
                  </div>
                </Card>
              </div>
            </Grid>
            {indexingsearch && (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className={commonClass.outerContainer}>
                  <Card elevation={0} className={commonClass.card}>
                    <h3>Farms</h3>
                    <div>
                      <div className={commonClass.checkBoxContainer}>
                        {farms.map((farm) => (
                          <RenderCheckbox
                            name={farm.Id.toString()}
                            checked={checkedItems[farm.Id]}
                            label={farm.Id + '-' + farm.DisplayName}
                            key={farm.Id}
                            handler={(e) => handleCheckboxChange(e)}
                          />
                        ))}
                      </div>
                    </div>
                  </Card>
                </div>
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {(webviewer ||
                indexingsearch ||
                appGateway ||
                formatconverter ||
                DeployDTS) && (
                <BranchCard
                  branches={branches}
                  dtsBranches={dtsBranches}
                  handleFieldChange={handleFieldChange}
                  selectedBranch={selectedBranch}
                  selectedDtsBranch={selectedDtsBranch}
                />
              )}
            </Grid>
          </Grid>
          <DeploymentFooter DeployAppServices={DeployGlobalService} />
        </Paper>
      </div>
    </>
  );
}
