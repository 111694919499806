import { callService } from '../../../Service/ServiceBase';
import { RequestType } from '../../../Service/RequestType';
import {
  Customer,
  CustomerV2,
  CustomerModule,
  CustomerModuleDTO,
  CollaborationRelayInfo,
  CreateCustomerConstants,
  NewCustomer,
} from './Model/CustomerModel';
import { PagedData } from '../../Common/CommonModel';
import { Tenant } from '../Farm/Model/FarmModel';
import { MoveTenant, RegisterTenant } from '../Tenant/Model/TenantModels';
class CustomerService {
  getCustomers(): Promise<PagedData<Customer>> {
    const pagedCustomerResult = callService('customers', '', RequestType.GET);
    return pagedCustomerResult;
  }

  getCustomer(customerId: string): Promise<CustomerV2> {
    const pagedCustomerResult = callService(
      'customers',
      customerId,
      RequestType.GET,
    );
    return pagedCustomerResult;
  }
  setCustomer(customerId: string, customer: CustomerV2): Promise<CustomerV2> {
    const result = callService(
      'customers',
      customerId,
      RequestType.PUT,
      customer,
    );
    return result;
  }
  getAllCustomerModules(): Promise<PagedData<CustomerModule>> {
    const pagedModules = callService('customers', 'modules', RequestType.GET);
    return pagedModules;
  }
  getCustomerModules(
    customerId: string,
  ): Promise<PagedData<CustomerModuleDTO>> {
    const pagedModules = callService(
      'customers',
      customerId + '/modules',
      RequestType.GET,
    );
    return pagedModules;
  }
  getCollaborationRelayInfo(
    customerId: string,
  ): Promise<CollaborationRelayInfo> {
    const relayInfo = callService('dts', customerId, RequestType.GET);
    return relayInfo;
  }
  getRelayPassword(customerId: string): Promise<string> {
    const decryptedPassword = callService(
      'dts',
      'password/' + customerId,
      RequestType.GET,
    );
    return decryptedPassword;
  }
  getTenant(customerId: string, active: boolean): Promise<PagedData<Tenant>> {
    const route = active
      ? `${customerId}/tenants?active=true`
      : `${customerId}/tenants`;
    const decryptedPassword = callService('customers', route, RequestType.GET);
    return decryptedPassword;
  }
  getGroupNames(): Promise<PagedData<string>> {
    const goupnames = callService('groups', 'groupnames', RequestType.GET);
    return goupnames;
  }
  getDefaultValues(): Promise<CreateCustomerConstants> {
    const defaultvalues = callService(
      'customers',
      'createcustomer/defaultvalues',
      RequestType.GET,
    );
    return defaultvalues;
  }
  CreateCustomer(customer: NewCustomer): Promise<CustomerV2> {
    const result = callService(
      'customers',
      'createcustomer',
      RequestType.POST,
      customer,
    );
    return result;
  }
  getEditCustomer(customerId: string): Promise<NewCustomer> {
    const route = `customer/${customerId}`;
    const pagedCustomerResult = callService(
      'customers',
      route,
      RequestType.GET,
    );
    return pagedCustomerResult;
  }
  updateCustomer(customer: NewCustomer): Promise<NewCustomer> {
    const route = `editcustomer`;
    const pagedCustomerResult = callService(
      'customers',
      route,
      RequestType.PUT,
      customer,
    );
    return pagedCustomerResult;
  }
  async getRegisterTenant(customerId: string): Promise<RegisterTenant> {
    const route = `createtenant/${customerId}`;
    const registerTenant = await callService(
      'customers',
      route,
      RequestType.GET,
    );
    return registerTenant;
  }

  RegisterTenant(
    customerId: string,
    FarmId: string,
    storageAccount: string,
    appsevicePlan: string,
  ): Promise<NewCustomer> {
    const route = `createtenant/${customerId}/${FarmId}/${storageAccount}/${appsevicePlan}`;
    const pagedCustomerResult = callService(
      'customers',
      route,
      RequestType.POST,
    );
    return pagedCustomerResult;
  }
  async getMoveTenant(customerId: string): Promise<MoveTenant> {
    const route = `movetenant/${customerId}`;
    const registerTenant = await callService(
      'customers',
      route,
      RequestType.GET,
    );
    return registerTenant;
  }
  async IsCustomerOnFarm(customerId: string): Promise<boolean> {
    const route = `iscustomeronfarm/${customerId}`;
    const registerTenant = await callService(
      'customers',
      route,
      RequestType.GET,
    );
    return registerTenant;
  }

  DeleteCustomer(customerId: string): void {
    const route = `${customerId}`;
    callService('customers', route, RequestType.DELETE);
  }

  getCustomersByProduct(product: string): Promise<PagedData<Customer>> {
    return callService('customers', `product/${product}`, RequestType.GET);
  }

  getContactsByCustomerId(customerId: string): Promise<string[]> {
    return callService(
      'customers',
      `contacts?customerId=${customerId}`,
      RequestType.GET,
    );
  }

  getContactsByfarmId(farmId: number): Promise<string[]> {
    return callService(
      'customers',
      `contacts?farmId=${farmId}`,
      RequestType.GET,
    );
  }
  getContacts(): Promise<string[]> {
    return callService('customers', `contacts`, RequestType.GET);
  }

  validateSubdomain(subdomain: string): boolean {
    return callService(
      'customers',
      `validate/subdomain/${subdomain}`,
      RequestType.GET,
    );
  }
}
export const customerService: CustomerService = new CustomerService();
