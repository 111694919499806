export interface FarmTopologies {
  Location: string;
  FarmType: string;
  DisplayName: string;
  InfrastructureType: string;
  DefaultTopology: null;
  DefaultSubscription: string;
}

export interface FarmMenuItems {
  id: string;
  label: string;
  linkTo: string;
  validationFunc: string;
}

export interface FarmMenuProps {
  FarmId: string;
}

export interface Resources {
  Filename: string;
  Container: string;
  Uri: string;
  LastModified: string;
  ContentMD5: string;
}

export enum GlobalComponent {
  WEBVIEW = 0,
  FORMATCONVERTER = 1,
  APPGATEWAY = 2,
  INDEXINGSEARCH = 3,
}

export interface ReleaseRequest {
  farmId?: number;
  tenantIds?: string[];
  components?: string[];
  buildID?: string;
  branch?: string;
  rollback?: boolean;
  deployOnly?: boolean;
  GlobalComponent?: GlobalComponent;
  disasterRecovery?: boolean;
  migrateFromOnpremise?: boolean;
  trustedClients?: boolean;
  farmReset?: boolean;
  installModules?: boolean;
  deployAppGateway?: boolean;
  moveTenant?: boolean;
  sourceFarmID?: string;
  destFarmID?: string;
  tenentToMove?: string;
  newAppPlanName?: string;
  dtsBranch?: string;
  deployDTS?: boolean;
}

export interface Tenant {
  Id: string;
  InsertDate: Date;
  FarmId: number;
  ApplicationUrl: string;
  StatusID: number;
  DeploymentState: number;
  SourceFarmID: number | null;
  WebApplicationPort: number | null;
  BusinessLogicPort: number | null;
  StorageKeyType: number;
  AppDBName: string;
  ContentDBName: string;
  AzureSQLServerName: string;
  BLDBUserName: string;
  BLDBUserPassword: string;
  InstallDBUserName: string;
  InstallDBUserPassword: string;
  RODBUserName: string;
  ROUserPassword: string;
  WFEUserPassword: string;
  KeyRotationCounter: number;
  Properties: string;
  AppservicePlan: string;
  Version: number[];
  Farm: Farm;
  StorageAccountName: string;
  OnboardedDate: string;
}

export interface Farm {
  Id: number;
  InsertDate: Date;
  DisplayName: string;
  AdminPassword: string;
  BLUserPassword: string;
  ClonedFrom: number | null;
  State: number;
  DeploymentState: number;
  CurrentDeploymentState: string;
  SetupFilename: string;
  NextSetupFilename: string;
  Location: string;
  Type: string;
  Topology: string;
  Subscription: string;
  Properties: string;
  UpgradeOn: Date | null;
  SubState: string;
  Version: number[];
  GroupName: string;
  AzureFarmDeploymentProperties: string;
  FarmRegion: string;
  SecondaryRegion: string;
  IsHighAvailability: boolean;
}
