import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Toolbar,
  Typography,
  InputBase,
  TableRow,
  TableBody,
  IconButton,
  Backdrop,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Table from '@mui/material/Table';
import DialogTitle from '@mui/material/DialogTitle';
import SaveIcon from '@mui/icons-material/Save';
import Cancel from '@mui/icons-material/Cancel';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { toast } from 'react-toastify';
import { ClientSecrete } from '../Model/ClientSecretesModel';
import { clientsecretService } from '../ClientSecretService';
import { useParams } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import ClientSecretContants from '../Model/ClientSecretConstants';
import {
  TableCell,
  toastforClientIdCopy,
  toastforSecretCopy,
  useToolbarStyles,
} from '../CommonMethods/ClientSecretCommonMethods';
import { ClientsSecreteToolbarProps } from '../Model/ClientsSecretesProps';

interface Param {
  customerId: string;
}
export default function ClientsSecreteHeaderToolbar(
  props: Readonly<ClientsSecreteToolbarProps>,
) {
  const { onFilterRequest, customer, oncloseUpdateParent } = props;
  const [isSending, setIsSending] = useState(false);
  const params = useParams<Param>();
  const [field, setField] = useState<ClientSecrete>();
  const [Description, setFieldsDescription] = React.useState('');

  const classes = useToolbarStyles();
  const createFilterHandler = () => (event: ChangeEvent<HTMLInputElement>) => {
    onFilterRequest(event);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    async function fetchData() {
      await clientsecretService
        .setClientSecret(params.customerId, Description)
        .then((data) => {
          setField(data);
          toast.success(ClientSecretContants.GenerateSuccess);
        })
        .then(() => setIsSending(false));
    }
    isSending && validateDescription(Description) && fetchData();
  }, [isSending]);

  const handleClose = () => {
    setOpen(false);
    setField(undefined);
    setIsSending(false);
    setFieldsDescription('');
    oncloseUpdateParent();
  };

  const validateDescription = (str: string): boolean | undefined => {
    if (str === '') {
      alert(ClientSecretContants.DescriptionNullAlert);
      setIsSending(false);
    } else {
      return true;
    }
  };

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    setFieldsDescription(value);
  }

  return (
    <Toolbar className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6" id="tableTitle">
          {ClientSecretContants.ClientSecretHeader} {customer.Subdomain}
        </Typography>
      </div>
      <div className={classes.spacer} />
      <div className={classes.searchOption}>
        <div className={classes.IconSearch}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Search…"
          classes={{
            root: classes.RootInput,
            input: classes.input,
          }}
          inputProps={{ 'aria-label': 'Search' }}
          onChange={createFilterHandler()}
        />
      </div>
      <Tooltip title={ClientSecretContants.GenerateClientSecretToolTip}>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          className={classes.button}
          startIcon={<AddIcon />}
        >
          Add
        </Button>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={field?.Secret ? 'lg' : 'md'}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Client Secret</DialogTitle>
        <DialogContent>
          <Typography>{ClientSecretContants.ClientSecretAlert}</Typography>
          <br />
          <Table style={{ width: 800, textAlign: 'left' }}>
            <TableBody>
              <TableRow>
                {field ? (
                  <TableCell>Api Key</TableCell>
                ) : (
                  <TableCell>Description (Purpose) : </TableCell>
                )}
                {field ? (
                  <TableCell>
                    {': ' + field.ClientId}
                    <CopyToClipboard
                      text={field.ClientId}
                      onCopy={() => {
                        toastforClientIdCopy();
                      }}
                    >
                      <IconButton size="large">
                        <FileCopyIcon fontSize="small" />
                      </IconButton>
                    </CopyToClipboard>
                  </TableCell>
                ) : (
                  <TableCell>
                    <TextField
                      required
                      className={classes.medtextbox}
                      variant="outlined"
                      placeholder="Describe"
                      inputProps={{ maxLength: 75 }}
                      name="Description"
                      value={Description}
                      onChange={(e) =>
                        handleChange(e as React.ChangeEvent<HTMLInputElement>)
                      }
                    />
                  </TableCell>
                )}
              </TableRow>
              {field ? (
                <TableRow>
                  <TableCell>Secret</TableCell>
                  <TableCell>
                    {': ' + field.Secret}
                    <CopyToClipboard
                      text={field.Secret}
                      onCopy={() => {
                        toastforSecretCopy();
                      }}
                    >
                      <IconButton size="large">
                        <FileCopyIcon fontSize="small" />
                      </IconButton>
                    </CopyToClipboard>
                  </TableCell>
                </TableRow>
              ) : (
                <Backdrop className={classes.backdrop} open={isSending}>
                  <CircularProgress color="inherit" />
                </Backdrop>
              )}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          {!field ? (
            <Button
              variant="contained"
              onClick={() => setIsSending(true)}
              className={classes.button}
              startIcon={<SaveIcon />}
            >
              Create{' '}
            </Button>
          ) : null}
          <Button
            variant="contained"
            onClick={handleClose}
            className={classes.button}
            startIcon={<Cancel />}
          >
            Cancel{' '}
          </Button>
        </DialogActions>
      </Dialog>
    </Toolbar>
  );
}
