import { RequestType } from '../../../Service/RequestType';
import { callService } from '../../../Service/ServiceBase';
import { PagedData } from '../../Common/CommonModel';
import { Tenant } from '../ManageFarms/Model/FarmModel';
import {
  CloneTenantMetaData,
  MoveTenant,
  RegisterTenant,
} from './Model/TenantModels';
class TenantService {
  getRegisterTenant(customerId: string): Promise<RegisterTenant> {
    const route = `createtenant/${customerId}`;
    const registerTenant = callService('customers', route, RequestType.GET);
    return registerTenant;
  }

  MoveTenant(
    customerId: string,
    movetenant: MoveTenant,
    StorageAccountName: string,
  ): Promise<MoveTenant> {
    const route = `movetenant/${customerId}/${StorageAccountName}`;
    const status = callService(
      'customers',
      route,
      RequestType.POST,
      movetenant,
    );
    return status;
  }

  cloneTenant(tenantId: string, clonetenant: CloneTenantMetaData) {
    const route = `${tenantId}/clone`;
    callService('tenant', route, RequestType.POST, clonetenant, true);
  }

  getTenants(): PagedData<Tenant> {
    return callService('tenant', '', RequestType.GET);
  }
}
export const tenantService: TenantService = new TenantService();
