import {
  Backdrop,
  Button,
  CircularProgress,
  Divider,
  Paper,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useToolbarStyles } from '../Customer/Home/CustomerHeaderToolbar';
import SaveIcon from '@mui/icons-material/Save';
import Cancel from '@mui/icons-material/Cancel';
import { useHistory, useParams } from 'react-router-dom';
import { RegisterTenant, TenantForm } from './Model/TenantModels';
import { customerService } from '../Customer/CustomerService';
import { toast } from 'react-toastify';
import { storageAccountService } from '../StorageAccount/StorageAccountService';
import { StorageAccount } from '../StorageAccount/Model/StorageAccountModel';
import { farmService } from '../ManageFarms/FarmService';
import {
  RemoveDuplicateAppserviceplans,
  createNewAppServicePlan,
} from '../../../Common/CommonMethods';
import { plans } from '../../Common/Constants/ValueConstants';
import TenantRegistrationForm from './Components/TenantRegistrationForm';
import { ValidationErrors } from 'fluentvalidation-ts';

interface Param {
  customerId: string;
}
export default function TenantRegistration() {
  const params = useParams<Param>();
  const classes = useToolbarStyles();
  const history = useHistory();
  const [registerTenant, setRegisterTenant] = useState<RegisterTenant>();
  const [FarmId, setFarmId] = useState<string>();
  const [loading, setLoading] = useState(true);
  const initialStorageAccounts: StorageAccount[] = [];
  const [storageAccounts, setStorageAccounts] = React.useState(
    initialStorageAccounts,
  );
  const [openAppserviceDialogue, setOpenAppserviceDialogue] =
    React.useState(false);
  const [storageAccount, setStorageAccount] = React.useState('');
  const [appserviceplans, setAppserviceplans] = React.useState<string[]>([]);
  const [appserviceplan, setAppserviceplan] = React.useState('');
  const [selectedValue, setSelectedValue] = React.useState(plans[0]);
  const [tenantErrors, setTenantErrors] = useState<
    ValidationErrors<TenantForm>
  >({});

  const loadRegisterTenant = async () => {
    await customerService.getRegisterTenant(params.customerId).then((data) => {
      setRegisterTenant(data);
      setLoading(false);
    });
  };

  const addItemToState = (item: string[]) => {
    const newItem = createNewAppServicePlan(
      appserviceplans,
      item,
      Number(FarmId),
    );
    const appAppservicePlans = appserviceplans.concat(appserviceplans, newItem);
    setAppserviceplans(RemoveDuplicateAppserviceplans(appAppservicePlans));
  };

  const handleCloseAppservicePlan = (value: string) => {
    setOpenAppserviceDialogue(false);
    if ('Not Selected' !== value) {
      setSelectedValue(value);
      const appserviceplan: string[] = [];
      appserviceplan.push(value);
      addItemToState(appserviceplan);
    }
  };

  const loadStorageAccounts = async () => {
    try {
      await storageAccountService
        .getstorageAccounts()
        .then((storageAccounts) => {
          setStorageAccounts(
            storageAccounts.Items.filter(
              (sa) => sa.Purpose.toLowerCase() === 'archive',
            ),
          );
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handlRegister = async () => {
    if (
      FarmId !== undefined &&
      storageAccount !== '' &&
      appserviceplan !== ''
    ) {
      setLoading(true);
      await customerService
        .RegisterTenant(
          params.customerId,
          FarmId,
          storageAccount,
          appserviceplan,
        )
        .then(() => {
          toast.success('Tenant Registered Successfully!');
          setLoading(false);
        });
      history.push('/customer');
    } else {
      toast.error('All fields are required');
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setFarmId(value);
    const farmId = Number(value);
    console.log(registerTenant?.Farms.find((farm) => farm.Id === farmId));
    loadAppserviceplans(farmId);
    setStorageAccounts(
      storageAccounts.filter(
        (sa) =>
          sa.AzureRegion ===
          registerTenant?.Farms.find((farm) => farm.Id === farmId)?.FarmRegion,
      ),
    );
    setStorageAccount('');
  };
  const handleClose = () => {
    history.push('/customer');
  };

  const loadAppserviceplans = (farmId: number) => {
    farmService.getAppservicePlans(farmId).then((appserviceplans) => {
      setAppserviceplans(appserviceplans);
    });
  };

  const handleClickOpen = () => {
    setOpenAppserviceDialogue(true);
  };

  useEffect(() => {
    loadRegisterTenant().catch((err) => console.log(err));
    loadStorageAccounts().catch((err) => console.log(err));
  }, []);
  return (
    <div>
      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Paper>
          <Toolbar className={classes.root}>
            <div className={classes.title}>
              <Typography variant="h6" id="tableTitle">
                Register Tenant for Customer
              </Typography>
            </div>
          </Toolbar>
          <TenantRegistrationForm
            FarmId={FarmId ?? ''}
            appserviceplan={appserviceplan}
            setAppserviceplan={setAppserviceplan}
            appserviceplans={appserviceplans}
            customerId={params.customerId}
            storageAccount={storageAccount}
            setStorageAccount={setStorageAccount}
            storageAccounts={storageAccounts}
            handleChange={handleChange}
            handleClickOpen={handleClickOpen}
            registerTenant={registerTenant}
            handleCloseAppservicePlan={handleCloseAppservicePlan}
            openAppserviceDialogue={openAppserviceDialogue}
            selectedValue={selectedValue}
            setOpenAppserviceDialogue={setOpenAppserviceDialogue}
            errors={tenantErrors}
            setErrors={setTenantErrors}
          />
          <Divider className={classes.divider} />
          <Paper style={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={handlRegister}
              startIcon={<SaveIcon />}
            >
              Save{' '}
            </Button>
            <Button
              variant="contained"
              onClick={handleClose}
              className={classes.button}
              startIcon={<Cancel />}
            >
              Cancel{' '}
            </Button>
          </Paper>
        </Paper>
      )}
    </div>
  );
}
