import React from 'react';
import {
  Box,
  Divider,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import TenantSkeleton from './Skeletons/TenantSkeleton';
import { useStyles } from '../Common/CommonStyle';
import PublicIcon from '@mui/icons-material/Public';
import { GlobalSiteHealthStatus, Props } from '../Model/HealthModels';
import { healthService } from '../Services/HealthService';
import {
  Item,
  StatusIcon,
  TableCell,
  customSort,
} from '../Common/CommonMethods';
import makeStyles from '@mui/styles/makeStyles';

const useSelfStyles = makeStyles({
  WrapperPaper: {
    display: 'flex',
    marginBottom: 5,
  },
});

const GlobalHealth = ({ Product }: Props) => {
  const classes = useStyles();
  const selfStyle = useSelfStyles();
  const [loading, setLoading] = React.useState(false);
  const initialGlobalsites: GlobalSiteHealthStatus[] = [];
  const [globalSitesHealthStatus, setGlobalSitesHealthStatus] =
    React.useState(initialGlobalsites);

  React.useEffect(() => {
    loadTenantHealthstatus();
  }, [Product]);
  const loadTenantHealthstatus = async () => {
    try {
      setLoading(true);
      const globalsites = await healthService.GetGlobalsitesHealth(Product);
      setGlobalSitesHealthStatus(globalsites.Items);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Paper className={selfStyle.WrapperPaper} elevation={0}>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <TableHead>
          <TableRow className={classes.TableRow}>
            <TableCell>
              <PublicIcon
                color="primary"
                fontSize="medium"
                className={classes.HealthHeaderIcon}
              />
              GLOBAL HEALTH
            </TableCell>
          </TableRow>
        </TableHead>
        <Divider />
        <Table>
          <TableBody className={classes.TableBody}>
            {loading ? (
              <TenantSkeleton numberofTime={5} />
            ) : (
              <Box sx={{ width: '100%' }}>
                <Grid container spacing={{ xs: 2, md: 3 }} padding={2}>
                  {globalSitesHealthStatus
                    .sort((a, b) => customSort(a, b))
                    .map((globalsite) => (
                      <Grid item xs={4} sm={12} md={2} key={globalsite.Id}>
                        <Item>
                          <div>{globalsite.Id}</div>
                          <div>
                            <Link href={globalsite.Url} target="_blank">
                              {StatusIcon(globalsite.Status)}
                            </Link>
                          </div>
                        </Item>
                      </Grid>
                    ))}
                </Grid>
              </Box>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default GlobalHealth;
