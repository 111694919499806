import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import MenuIcon from '@mui/icons-material/Menu';
import CustomBreadcrumbs from './Global/BreadCrumb';
import { PortalBarProps } from './Global/RouteProps';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box, Tooltip } from '@mui/material';
import TogglethemeControl from './ToggleTheme';
import AccountMenu from './Account';

const drawerWidth = 240;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      paddingRight: 24,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginLeft: 12,
      marginRight: 36,
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
    },
    drawer: {
      width: 240,
    },
    appBarNotification: {
      zIndex: 1250,
    },
  }),
);

export default function PortalBar(props: PortalBarProps) {
  const { drawerOpen, clickOpen, routes, notificationClick } = props;
  const classes = useStyles();

  const handleOpen = () => {
    clickOpen();
  };
  const RouteHelp = () => {
    window.open(
      window.location.protocol + '//' + window.location.hostname + '/help',
      '_blank',
    );
  };

  return (
    <Box>
      <AppBar
        position="absolute"
        className={drawerOpen ? classes.appBarShift : classes.appBar}
      >
        <Toolbar disableGutters={!drawerOpen} className={classes.toolbar}>
          <IconButton
            aria-label="Open drawer"
            onClick={handleOpen}
            className={
              drawerOpen ? classes.menuButtonHidden : classes.menuButton
            }
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <CustomBreadcrumbs routes={routes} />
          <Typography
            component="h1"
            variant="h6"
            noWrap
            className={classes.title}
          >
            {' '}
          </Typography>
          <Tooltip title={'Help'}>
            <IconButton onClick={RouteHelp}>
              <HelpOutlineIcon color="secondary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Notifications">
            <IconButton onClick={() => notificationClick()}>
              <NotificationsIcon color="secondary" />
            </IconButton>
          </Tooltip>
          <TogglethemeControl />
          <AccountMenu />
        </Toolbar>
      </AppBar>
    </Box>
  );
}
