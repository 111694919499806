import { AuthProvider } from '.';

export function runWithMsal(authprovider: AuthProvider, app: () => void) {
  const account = authprovider.getAccount();
  if (account) {
    app();
  } else {
    authprovider.login().then(() => app());
  }
}
