import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { farmService } from '../FarmService';
import { Farm, FarmTopologies } from '../Model/FarmModel';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableFooter,
  Tooltip,
  Collapse,
  IconButton,
  Autocomplete,
  TextField,
} from '@mui/material';
import FarmMenu from '../Components/FarmMenu';
import { Link } from 'react-router-dom';
import { farmStateMapping } from '../StateMapper/StateMapper';
import { AddCircle, CloudUpload, Storage, SyncLock } from '@mui/icons-material';
import SellIcon from '@mui/icons-material/Sell';
import FarmContants from '../Model/FarmConstants';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TenantTable from '../Components/TenantTable';
import CommonTag from '../../../Common/Tag/CommonTag';
import { storageAccountService } from '../../StorageAccount/StorageAccountService';
import { StorageAccount } from '../../StorageAccount/Model/StorageAccountModel';
import { toast } from 'react-toastify';
import { TagDetails } from '../../../Common/CommonModel';
import { globalService } from '../../UIContainer/Global/GlobalService';
import MenuFab from '../../../Common/Fab/MenuFab';
import IndexingSearchMachine from '../Components/IndexingSearchMachine';

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 500,
  },
  button: {
    float: 'right',
  },
  backdrop: {
    color: '#fff',
  },
  autoComplete: {
    minWidth: 400,
  },
}));

export default function CustomPaginationActionsTable() {
  const initialState: Farm[] = [];
  const initialTopologyState: FarmTopologies[] = [];
  const classes = useStyles();
  const [multiinstancefamrs, setFarms] = React.useState(initialState);
  const [multiinstancefamrscopy, setFarmsCopy] = React.useState(initialState);
  const [loading, setLoadingState] = React.useState(true);
  const [farmtopologies, setFarmTopologies] =
    React.useState(initialTopologyState);
  const [Type, setType] = React.useState('ProArc Online');
  const [page, setPage] = React.useState(0);
  const rowsPerPage = 10;
  const [openId, setOpenId] = React.useState<number | null>(null);

  const initialStorageAccounts: StorageAccount[] = [];
  const [storageAccounts, setStorageAccounts] = React.useState(
    initialStorageAccounts,
  );
  const [showTags, setShowtags] = React.useState(false);
  const [options, setOptions] = React.useState<string[]>([]);
  const [selectedFarm, setSelectedFarm] = React.useState('');
  const [farmTags, setFarmTags] = React.useState<string[]>([]);
  const [farmTagDetails, setFarmtagDetails] = React.useState<TagDetails[]>([]);
  const initialselectedFarmTags: string[] = [];
  const [selectedFarmTags, setSelectedFarmTags] = React.useState(
    initialselectedFarmTags,
  );
  const [showMenus, setShowMenus] = React.useState(true);

  function handleChangePage(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) {
    setPage(newPage);
  }

  useEffect(() => {
    loadTags();
    loadFarmTags();
    loadFarmTopologiesData().catch((err) => console.log(err));
    loadFarmData().catch((err) => console.log(err));
    loadStorageAccounts().catch((err) => console.log(err));
  }, []);

  const loadFarmTopologiesData = async () => {
    const farmsTopologiesReceived = await farmService.getFarmTopologies();
    setFarmTopologies(farmsTopologiesReceived.Items);
  };

  const loadFarmData = async () => {
    try {
      const farmsReceived = await farmService.getFarms();
      setFarms(farmsReceived.Items);
      setFarmsCopy(farmsReceived.Items);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingState(false);
    }
  };

  const updateSSlBindings = async () => {
    try {
      const proceed = window.confirm('You want to update ssl bindings?');
      if (proceed) {
        farmService.updateSSlBindings();
        toast.success(FarmContants.QueuedSSLRunbook);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loadStorageAccounts = async () => {
    try {
      await storageAccountService
        .getstorageAccounts()
        .then((storageAccounts) => {
          setStorageAccounts(
            storageAccounts.Items.filter(
              (sa) =>
                sa.ProductID === Type && sa.Purpose.toLowerCase() === 'archive',
            ),
          );
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event: SelectChangeEvent<string>) => {
    setType(event.target.value);
    const _vals = event.target.value
      ? multiinstancefamrscopy.filter(
          (farm) => farm.Type === event.target.value,
        )
      : multiinstancefamrscopy;
    setFarms(_vals);
  };

  const loadTags = async () => {
    const tags = await globalService.getTags();
    setOptions(tags.Items.map((x) => x.TagName));
  };

  const loadFarmTags = async () => {
    await globalService.getTagDetailsByObjectType('farm').then((tags) => {
      setFarmtagDetails(tags.Items);
      const uniqueTagNames = [...new Set(tags.Items.map((x) => x.TagName))];
      setFarmTags(uniqueTagNames);
    });
  };

  const onSelectedFarmTag = (tags: string[]) => {
    setSelectedFarmTags(tags);
    const farmIds = farmTagDetails
      .filter((x) => tags.includes(x.TagName))
      .map((tagdetails) => tagdetails.ObjectId);
    let filteredCustomers: Farm[] = [];
    if (farmIds !== null && farmIds.length > 0) {
      filteredCustomers = multiinstancefamrscopy.filter((farm) => {
        return farmIds.includes(farm.Id.toString());
      });
      setFarms(filteredCustomers);
    } else {
      setFarms(multiinstancefamrscopy);
    }
  };

  const SaveTags = async (selected: string[]) => {
    setLoadingState(true);
    const tagsDetails: TagDetails[] = [];
    selected.forEach((tag: string) => {
      const tagdetails: TagDetails = {
        ObjectId: selectedFarm,
        ObjectType: 'farm',
        TagName: tag,
      };
      tagsDetails.push(tagdetails);
    });
    await globalService
      .saveTagDetails(selectedFarm, tagsDetails)
      .then((tagdetails) => {
        setOptions(tagdetails.Items.map((x) => x.TagName));
        toast.success(
          'Tags for farm ' + selectedFarm.toString() + ' Saved Successfully',
        );
        loadTags();
        loadFarmTags();
        setShowtags(false);
        setLoadingState(false);
      });
  };

  return (
    <Paper>
      <Box style={{ padding: '20px' }}>
        <Grid justifyContent="space-between" container>
          <Grid item>
            <Tooltip title={FarmContants.SelectSubscription} placement="right">
              <Select
                labelId="Subscription"
                id="Subscription"
                variant="outlined"
                value={Type}
                onChange={handleChange}
                name="Subscription"
              >
                {farmtopologies.map((topology) => {
                  return (
                    <MenuItem key={topology.FarmType} value={topology.FarmType}>
                      {topology.FarmType}
                    </MenuItem>
                  );
                })}
              </Select>
            </Tooltip>
          </Grid>
          <Grid>
            {showMenus ? (
              <Grid container spacing={3}>
                <Grid item></Grid>
                <Grid item>
                  <Autocomplete
                    className={classes.autoComplete}
                    multiple
                    value={selectedFarmTags}
                    options={farmTags}
                    onChange={(event, newValue) => {
                      onSelectedFarmTag(newValue);
                    }}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <div>
                        <TextField
                          placeholder="Select Tags"
                          {...params}
                          size="small"
                        />
                      </div>
                    )}
                  />
                </Grid>
                <Grid item>
                  <Tooltip title={FarmContants.UpdateSslBindings}>
                    <Button
                      className={classes.button}
                      variant="contained"
                      startIcon={<SyncLock />}
                      onClick={updateSSlBindings}
                    >
                      {FarmContants.UpdateSslBindings}
                    </Button>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title={FarmContants.StorageAccount}>
                    <Button
                      className={classes.button}
                      variant="contained"
                      startIcon={<Storage />}
                      component={Link}
                      to={'/storageaccount'}
                    >
                      {FarmContants.StorageAccount}
                    </Button>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title={'Deployment Center'}>
                    <Button
                      className={classes.button}
                      variant="contained"
                      startIcon={<CloudUpload />}
                      component={Link}
                      to={'/farm/{0}/deploymentcenter'.format(Type)}
                    >
                      {'Deployment Center'}
                    </Button>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title={FarmContants.CreateFarm}>
                    <Button
                      className={classes.button}
                      variant="contained"
                      startIcon={<AddCircle />}
                      component={Link}
                      to="/farm/new"
                    >
                      {FarmContants.NewFarm}
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <MenuFab setShowMenus={setShowMenus} showMenus={showMenus} />
      </Box>
      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="left"></TableCell>
              <TableCell align="left">Id</TableCell>
              <TableCell align="left">Display Name</TableCell>
              <TableCell align="left">Group Name</TableCell>
              <TableCell align="left">State</TableCell>
              <TableCell align="left">High Availability</TableCell>
              <TableCell align="left">Farm Region</TableCell>
              <TableCell align="left">Setup</TableCell>
              <TableCell align="left">Indexing Machine</TableCell>
              <TableCell align="left">Tags</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {showTags ? (
              <CommonTag
                objectType="Farm"
                saveTags={SaveTags}
                objectId={selectedFarm}
                options={options}
                setShowTags={setShowtags}
              />
            ) : null}
            {(Type === 'ProArc Online'
              ? multiinstancefamrs.filter(
                  (item) => item.Type === 'ProArc Online',
                )
              : multiinstancefamrs
            ).map((farm) => (
              <>
                <TableRow key={farm.Id.toString()}>
                  <TableCell width={34}>
                    <IconButton
                      size="small"
                      onClick={() => {
                        if (openId !== farm.Id) {
                          setOpenId(farm.Id);
                        } else {
                          setOpenId(null);
                        }
                      }}
                    >
                      {openId === farm.Id ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell
                    style={{ width: 160 }}
                    component="th"
                    scope="row"
                    align="left"
                  >
                    {farm.Id}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    {farm.DisplayName}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    {farm.GroupName}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    {farmStateMapping[farm.State]}
                    {farm.SubState ? ` (${farm.SubState})` : ''}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    {farm.IsHighAvailability ? 'Yes' : 'No'}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    {farm.FarmRegion}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    {farm.SetupFilename}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    <IndexingSearchMachine farmId={farm.Id} />
                    {/* <RevealPassword FarmId={farm.Id} /> */}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    <IconButton
                      onClick={() => {
                        setShowtags(true);
                        setSelectedFarm(farm.Id.toString());
                      }}
                    >
                      <SellIcon color="primary" />
                    </IconButton>
                  </TableCell>
                  <TableCell style={{ width: 100 }} align="left">
                    <FarmMenu FarmId={farm.Id.toString()}></FarmMenu>
                  </TableCell>
                </TableRow>
                {openId === farm.Id && (
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={12}
                    >
                      <Collapse
                        in={openId === farm.Id}
                        timeout="auto"
                        unmountOnExit
                      >
                        <TenantTable
                          farm={farm}
                          storageaccounts={storageAccounts.filter(
                            (sa) =>
                              sa.AzureRegion.toLowerCase() === farm.FarmRegion,
                          )}
                        />
                      </Collapse>
                    </TableCell>
                  </TableRow>
                )}
              </>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10]}
                count={multiinstancefamrs.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'Rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      )}
    </Paper>
  );
}
