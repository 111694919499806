import { Card, Grid, MenuItem, TextField } from '@mui/material';
import { ValidationErrors, Validator } from 'fluentvalidation-ts';
import React, { useEffect } from 'react';
import { DeployDetailsForm } from '../../Tenant/Model/TenantModels';
import { useStyles } from '../../../../Common/Styles/FarmStyle';

class FormValidator extends Validator<DeployDetailsForm> {
  constructor() {
    super();
    this.ruleFor('Branch').notEmpty().withMessage('Branch cannot be empty');
  }
}

const DeploymentForm = (props: {
  branches: string[];
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  deployDetailsForm: DeployDetailsForm;
  errors: ValidationErrors<DeployDetailsForm>;
  setErrors: React.Dispatch<
    React.SetStateAction<ValidationErrors<DeployDetailsForm>>
  >;
}) => {
  const classes = useStyles();
  const { branches, handleChange, deployDetailsForm, errors, setErrors } =
    props;

  const formValidator = new FormValidator();

  const validate = () => {
    const formErrors = formValidator.validate(deployDetailsForm);
    setErrors(formErrors);
  };

  useEffect(() => {
    validate();
  }, []);

  return (
    <>
      <div className={classes.outerContainer}>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Card elevation={0} className={classes.card}>
              <h3>Basic information</h3>
              <div>
                <div className={classes.spanContainer}>
                  <span className={classes.span}>Platform Branch</span>
                  <TextField
                    className={classes.textField}
                    error={!!errors.Branch}
                    helperText={errors.Branch}
                    value={deployDetailsForm.Branch}
                    placeholder="Platform Branch"
                    name="Branch"
                    onChange={handleChange}
                    onBlur={validate}
                    select
                  >
                    {branches.map((branchname) => {
                      return (
                        <MenuItem key={branchname} value={branchname}>
                          {branchname.replace('refs/heads/', '')}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              </div>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default DeploymentForm;
