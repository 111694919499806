import { RequestType } from './RequestType';
import { authProvider } from '../Auth';

export function callService(
  serviceName: string,
  methodName: string,
  requestType: RequestType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  postObject?: any,
  skipResp?: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  const win: Window = window;
  const baseUrl: string = win.config.apibaseurl;
  const completeURL: string = baseUrl + '/' + serviceName + '/' + methodName;

  if (skipResp) {
    return authProvider.getWebApiToken().then((token) => {
      return fetch(
        getRequest(completeURL, requestType, token, JSON.stringify(postObject)),
      ).then((response) => checkStatus(response));
    });
  } else {
    return authProvider.getWebApiToken().then((token) => {
      return fetch(
        getRequest(completeURL, requestType, token, JSON.stringify(postObject)),
      )
        .then((response) => checkStatus(response))
        .then((response) => parseJSON(response));
    });
  }
}

function getRequest(
  requestUri: string,
  requestType: RequestType,
  token: string | null,
  body?: string,
): Request {
  return new Request(
    requestUri,
    getRequestInitilization(body, requestType, token),
  );
}

function getRequestInitilization(
  body: string | undefined,
  requestType: RequestType,
  token: string | null,
): RequestInit {
  const requestInit: RequestInit = {
    body: body,
    headers: {
      Authorization: `Bearer ${token}`,
      'Cache-Control': 'no-cache',
      'Access-Control-Allow-Methods': '*',
      'Content-Type': 'application/json',
    },
    method: requestType,
  };
  return requestInit;
}

function checkStatus(response: Response): Promise<Response> {
  if (response.status >= 200 && response.status < 300) {
    return Promise.resolve(response);
  } else {
    return response.json().then((errMsg: object) => {
      throw {
        errorMessage: errMsg,
        status: response.status,
        statusText: response.statusText,
        url: response.url,
      };
    });
  }
}

function parseJSON(response: Response): Promise<object> {
  return response.text().then((text) => {
    try {
      return JSON.parse(text);
    } catch {
      return text;
    }
  });
}
