import React, { useEffect, useState } from 'react';
import {
  Paper,
  Typography,
  Card,
  Divider,
  Button,
  TableRow,
  MenuItem,
  FormControlLabel,
  Checkbox,
  FormGroup,
  TextField,
  ListItem,
  List,
  ListItemText,
  ListItemIcon,
  Box,
  Grid,
} from '@mui/material';
import { TableCell } from '../../DashBoard/Common/CommonMethods';
import {
  EmailTemplate,
  SendEmailTemplateMessage,
} from '../Model/EmailAdminModel';
import JoditTemplateEditor from '../../../Common/JodiEditor/JodiEditor';
import { emailadminService } from '../EmailAdministrationService';
import {
  CheckBoxItem,
  Dictionary,
  Product,
  sendToList,
} from '../../../Common/CommonModel';
import { commonService } from '../../../Common/CommonService';
import { farmService } from '../../Farm/FarmService';
import { customerService } from '../../Customer/CustomerService';
import {
  GetCustomerCheckBoxList,
  GetFarmCheckBoxList,
  GetTagCheckBoxList,
  IsValidEmail,
} from '../Common/Common';
import { globalService } from '../../UIContainer/Global/GlobalService';
import { Send } from '@mui/icons-material';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { capitalize } from '../../../../Helpers/UtilityMethods';
import EmailAdminConstants from '../Common/Constants';
import { usePageTour } from '../../../Common/PopUp/PageTour';
import { SendEmailPageSteps } from '../EmailTourSteps/SendEmailPageSteps';
import { useStyles } from '../../../../Common/Styles/WraperStyle';
import { useStyles as commonStyle } from '../../../../Common/Styles/FarmStyle';
import { Loader } from '../../../../Common/Components/Loader';

interface SendEmailProps {
  templates: EmailTemplate[];
  products: Product[];
}

interface SendToItemProps {
  checkboxList: CheckBoxItem[];
  setCheckBoxList: (value: React.SetStateAction<CheckBoxItem[]>) => void;
}

interface SendToProps {
  selectedSendTo: string;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  SendToMapping: Dictionary<SendToItemProps>;
}

const DisplaySendToItems = (props: SendToItemProps) => {
  return (
    <FormGroup>
      {props.checkboxList.map((item, index) => (
        <FormControlLabel
          key={index}
          control={
            <Checkbox
              key={item.Text}
              checked={item.Check}
              name={item.Text}
              onChange={(e) => {
                const updatedList = [...props.checkboxList];
                updatedList[index] = {
                  ...updatedList[index],
                  Check: e.target.checked,
                };
                props.setCheckBoxList(updatedList);
              }}
            />
          }
          label={item.Text}
        />
      ))}
    </FormGroup>
  );
};

const SendTo = (props: SendToProps) => {
  if (props.selectedSendTo === 'email') {
    return (
      <>
        <TableRow>
          <TableCell>
            <ContactMailIcon fontSize="large" />
          </TableCell>
          <TextField
            name="email"
            multiline
            sx={{ width: 350 }}
            maxRows={3}
            fullWidth
            helperText={EmailAdminConstants.EmapleExamplehlperText}
            value={props.email}
            onChange={(e) => props.setEmail(e.target.value)}
          />
        </TableRow>
      </>
    );
  } else if (
    props.selectedSendTo === 'farm' ||
    props.selectedSendTo === 'customer' ||
    props.selectedSendTo === 'tags'
  ) {
    return (
      <div style={{ padding: 5 }}>
        <DisplaySendToItems
          checkboxList={props.SendToMapping[props.selectedSendTo].checkboxList}
          setCheckBoxList={
            props.SendToMapping[props.selectedSendTo].setCheckBoxList
          }
        />
      </div>
    );
  }
};

const SendEmailOps = (props: SendEmailProps) => {
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [selectedSendTo, setSelectedSendTo] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('');
  const [showSendTo, setShowSendTo] = useState(false);
  const [emailTemplate, setEmailTemplate] = useState<EmailTemplate>();
  const [farmCheckBoxList, setFarmCheckBoxList] = useState<CheckBoxItem[]>([]);
  const [customerCheckBoxList, setCustomerCheckBoxList] = useState<
    CheckBoxItem[]
  >([]);
  const [tagCheckBoxList, setTagCheckBoxList] = useState<CheckBoxItem[]>([]);
  const [email, setEmail] = useState('');
  const history = useHistory();
  const [contactsList, setContactsList] = useState<string[]>(() =>
    Array.from(new Set<string>([])),
  );
  const [loadingState, setLoadingState] = useState(false);
  const [ccs, setCCS] = useState('');
  const classes = commonStyle();
  const wrapperClass = useStyles();
  usePageTour(SendEmailPageSteps);

  const SendToMapping: Dictionary<SendToItemProps> = {};
  SendToMapping['farm'] = {
    checkboxList: farmCheckBoxList,
    setCheckBoxList: setFarmCheckBoxList,
  };

  SendToMapping['customer'] = {
    checkboxList: customerCheckBoxList,
    setCheckBoxList: setCustomerCheckBoxList,
  };

  SendToMapping['tags'] = {
    checkboxList: tagCheckBoxList,
    setCheckBoxList: setTagCheckBoxList,
  };

  const loadFarms = (product: string) => {
    farmService
      .getFarmsByProduct(product)
      .then((farms) => setFarmCheckBoxList(GetFarmCheckBoxList(farms.Items)));
  };

  const loadCustomers = (product: string) => {
    customerService
      .getCustomersByProduct(product)
      .then((customers) =>
        setCustomerCheckBoxList(GetCustomerCheckBoxList(customers.Items)),
      );
  };

  const loadTags = () => {
    globalService
      .getTags()
      .then((tags) => setTagCheckBoxList(GetTagCheckBoxList(tags.Items)));
  };

  const loadContacts = async () => {
    setContactsList([]);
    setLoadingState(true);

    let promises: Promise<string[]>[] = [];

    if (selectedSendTo === 'customer') {
      promises = customerCheckBoxList
        .filter((customer) => customer.Check)
        .map((customer) =>
          customerService.getContactsByCustomerId(customer.Value),
        );
    } else if (selectedSendTo === 'farm') {
      promises = farmCheckBoxList
        .filter((farm) => farm.Check)
        .map((farm) => customerService.getContactsByfarmId(Number(farm.Value)));
    } else if (selectedSendTo === 'tags') {
      promises = tagCheckBoxList
        .filter((tag) => tag.Check)
        .map((tag) => globalService.getTagContacts(tag.Value));
    } else if (selectedSendTo === 'allcustomers') {
      const contacts = await customerService.getContacts();
      setContactsList((prevList) =>
        Array.from(new Set([...prevList, ...contacts])),
      );
      setLoadingState(false);
      return;
    }

    const allContacts: string[][] = await Promise.all(promises);
    const mergedContacts: string[] = ([] as string[]).concat(...allContacts);
    setContactsList((prevList) =>
      Array.from(new Set([...prevList, ...mergedContacts])),
    );

    setLoadingState(false);
  };

  const loadTemplate = (templateName: string, product: string) => {
    emailadminService
      .getEmailTemplate(templateName, product)
      .then((template) => setEmailTemplate(template));
  };

  useEffect(() => {
    loadData();
  }, [selectedProduct, selectedTemplate]);

  useEffect(() => {
    loadContacts();
  }, [customerCheckBoxList, farmCheckBoxList, tagCheckBoxList, selectedSendTo]);

  const loadData = () => {
    if (selectedProduct !== '' && selectedTemplate !== '') {
      loadFarms(selectedProduct);
      loadTemplate(selectedTemplate, selectedProduct);
      loadCustomers(selectedProduct);
      loadTags();
      setShowSendTo(true);
    }
  };

  const sendEmail = () => {
    if (emailTemplate) {
      setLoadingState(true);
      const sendEmailMessage: SendEmailTemplateMessage = {
        ToEmails: contactsList,
        Subject: emailTemplate.Subject,
        HtmlContent: emailTemplate.Content,
        CCEmails: [],
      };
      if (selectedSendTo === 'email') {
        const emails = email.split(';');
        emails.forEach((mail) => {
          if (IsValidEmail(mail)) {
            sendEmailMessage.ToEmails.push(mail);
          } else {
            toast.warning(mail + ' ' + EmailAdminConstants.MailIgnoreMessage);
          }
        });
      }
      const ccmails = ccs.split(';');
      ccmails.forEach((cc) => {
        if (IsValidEmail(cc)) {
          sendEmailMessage.CCEmails.push(cc);
        } else {
          toast.warning(cc + ' ' + EmailAdminConstants.MailIgnoreMessage);
        }
      });

      if (sendEmailMessage.ToEmails.length > 0) {
        emailadminService.sendEmailWithTemplate(sendEmailMessage).then(() => {
          toast.success(EmailAdminConstants.EmailSentSuccessfully);
          setLoadingState(false);
          history.push('/emailadministration');
        });
      } else {
        toast.error(EmailAdminConstants.EmailEmptyWarning);
      }
    }
  };

  return (
    <>
      <Loader loading={loadingState} />
      <div className={classes.outerContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Card elevation={0} className={classes.minCard}>
              <h3>Basic Details</h3>
              <div>
                <div className={classes.spanContainer}>
                  <span className={classes.span}>Product</span>
                  <TextField
                    className={classes.textField}
                    value={selectedProduct}
                    onChange={(e) => {
                      setSelectedProduct(e.target.value);
                    }}
                    select
                  >
                    {props.products.map((product) => (
                      <MenuItem key={product.Id} value={product.Id}>
                        {product.Id}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                {selectedProduct && (
                  <div className={classes.spanContainer}>
                    <span className={classes.span}>Template Name</span>
                    <TextField
                      className={classes.textField}
                      value={selectedTemplate}
                      onChange={(e) => setSelectedTemplate(e.target.value)}
                      select
                    >
                      {props.templates
                        .filter(
                          (template) => template.Product === selectedProduct,
                        )
                        .map((template) => (
                          <MenuItem key={template.Name} value={template.Name}>
                            {template.Name}
                          </MenuItem>
                        ))}
                    </TextField>
                  </div>
                )}
                {showSendTo && (
                  <>
                    <div className={classes.spanContainer}>
                      <span className={classes.span}>Send To</span>

                      <TextField
                        className={classes.textField}
                        value={selectedSendTo}
                        onChange={(e) => setSelectedSendTo(e.target.value)}
                        select
                      >
                        {sendToList.map((to) => (
                          <MenuItem key={to.Text} value={to.Value}>
                            {to.Text}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                    {emailTemplate && (
                      <div className={classes.spanContainer}>
                        <span className={classes.span}>Email Subject</span>
                        <TextField
                          multiline
                          maxRows={3}
                          fullWidth
                          className={classes.textField}
                          value={emailTemplate?.Subject}
                          onChange={(e) =>
                            setEmailTemplate({
                              ...emailTemplate,
                              Subject: e.target.value,
                            })
                          }
                        ></TextField>
                      </div>
                    )}
                    <div className={classes.spanContainer}>
                      <span className={classes.span}>Additional emails</span>
                      <TextField
                        helperText={EmailAdminConstants.CcHelperText}
                        multiline
                        maxRows={3}
                        fullWidth
                        className={classes.textField}
                        value={ccs}
                        onChange={(e) => setCCS(e.target.value)}
                      ></TextField>
                    </div>
                  </>
                )}
              </div>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            {selectedSendTo && selectedSendTo !== 'allcustomers' && (
              <Card elevation={0} className={classes.minCard}>
                <h3>{capitalize(selectedSendTo)}</h3>
                <Box className={classes.box}>
                  <TableRow>
                    {selectedSendTo && (
                      <SendTo
                        SendToMapping={SendToMapping}
                        email={email}
                        setEmail={setEmail}
                        selectedSendTo={selectedSendTo}
                      />
                    )}
                  </TableRow>
                </Box>
              </Card>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            {selectedSendTo && selectedSendTo !== 'email' && (
              <Card elevation={0} className={classes.minCard}>
                <h3>Email Recipients (Bcc)</h3>
                <Box className={classes.box}>
                  <List sx={{ padding: 2 }}>
                    {loadingState ? (
                      <Loader loading={loadingState} />
                    ) : (
                      contactsList.map((contact) => (
                        <ListItem key={contact}>
                          <ListItemIcon>
                            <ContactMailIcon />
                          </ListItemIcon>
                          <ListItemText primary={contact} />
                        </ListItem>
                      ))
                    )}
                  </List>
                </Box>
              </Card>
            )}
          </Grid>
        </Grid>
        {emailTemplate !== undefined ? (
          <Card sx={{ margin: 2 }}>
            <JoditTemplateEditor
              emailtemplate={emailTemplate}
              setEmailTemplate={
                setEmailTemplate as React.Dispatch<
                  React.SetStateAction<EmailTemplate | undefined>
                >
              }
            />
          </Card>
        ) : null}
      </div>
      <Divider className={wrapperClass.divider} />
      <Paper style={{ textAlign: 'right' }}>
        <Button
          variant="contained"
          className={wrapperClass.button}
          startIcon={<Send />}
          onClick={sendEmail}
        >
          Send{' '}
        </Button>
      </Paper>
    </>
  );
};

const SendEmailPage = () => {
  const [templates, setTemplates] = useState<EmailTemplate[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const wrapperClass = useStyles();

  useEffect(() => {
    Init();
  }, []);

  const Init = () => {
    loadTemplate();
    loadProducts();
  };

  const loadTemplate = () => {
    emailadminService
      .getEmailTemplates()
      .then((templates) => setTemplates(templates.Items));
  };

  const loadProducts = () => {
    commonService.getProducts().then((products) => setProducts(products.Items));
  };

  return (
    <div>
      <Paper>
        <Typography variant="h5" className={wrapperClass.title}>
          Send Email
        </Typography>
        <Divider className={wrapperClass.divider} />
        <SendEmailOps templates={templates} products={products} />
      </Paper>
    </div>
  );
};

export default SendEmailPage;
