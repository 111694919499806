import React, { useEffect, useState } from 'react';

import { metricsService } from '../MetricsService';
import { TenantMetricsData, TenantMetricsProps } from '../Model/MetricsModel';
import {
  CircularProgress,
  Theme,
  Card,
  CardContent,
  Typography,
  Divider,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Dictionary } from '../../../Common/CommonModel';
import { convertValueToString } from '../Helpers/MetricsHelpers';
import { capitalize } from '../../../../Helpers/UtilityMethods';

import OverallUserMetricsChart from './Charts/OverallUserMetricsChart';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '5px',
    },
    displayRoot: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    progress: {
      marginLeft: '50%',
      marginTop: '30%',
      color: theme.palette.primary.light.toString(),
    },
    papper: {
      margin: '5px',
      minWidth: 275,
      width: 275,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  }),
);

export default function TenantMetrics(props: TenantMetricsProps) {
  const classes = useStyles();

  const { farmId, tenantId } = props;

  const [latestTenantMetrics, setlatestTenantMetrics] = useState<
    TenantMetricsData[]
  >([]);
  const [customerMetricsNameMap, setCustomerMetricsNameMap] = useState<
    Dictionary<TenantMetricsData>
  >({});

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadMetrics();
  }, [tenantId]);

  const loadMetrics = async () => {
    setLoading(true);
    const metrics = await metricsService.getLatestTenantMetrics(tenantId);
    const metricsNameMapping: Dictionary<TenantMetricsData> = {};
    metrics.forEach((metric) => {
      metricsNameMapping[metric.Name] = metric;
    });
    setlatestTenantMetrics(metrics);
    setCustomerMetricsNameMap(metricsNameMapping);
    setLoading(false);
  };

  const getSelectedMetricsData = (metricName: string) => {
    return customerMetricsNameMap[metricName];
  };

  return (
    <div className={classes.root}>
      {loading ? (
        <CircularProgress className={classes.progress} />
      ) : (
        <div>
          <div className={classes.displayRoot}>
            {latestTenantMetrics.map((metrics) => (
              <Card className={classes.papper} key={metrics.Id}>
                <CardContent>
                  <Typography className={classes.pos} color="textSecondary">
                    {capitalize(getSelectedMetricsData(metrics.Name).Type)}
                  </Typography>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {getSelectedMetricsData(metrics.Name).DisplayName}:
                  </Typography>
                  <Typography variant="h5" component="h2">
                    {convertValueToString(getSelectedMetricsData(metrics.Name))}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </div>
          <Divider />
          <OverallUserMetricsChart
            farmId={farmId}
            tenantId={tenantId}
          ></OverallUserMetricsChart>
        </div>
      )}
    </div>
  );
}
