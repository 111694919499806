import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/index.css';
import App from './Components/App';
import * as serviceWorker from './serviceWorker';

import { runWithMsal, authProvider } from './Auth';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

runWithMsal(authProvider, () => {
  root.render(<App />);
  serviceWorker.unregister();
});
