import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  title: {
    justifyContent: 'center',
    textAlign: 'center',
    padding: '20px',
  },
  divider: {
    marginTop: 5,
    marginBottom: 10,
  },
  button: {
    margin: theme.spacing(1),
    width: 200,
  },
}));
