import React from 'react';
import { Chip, Paper, Theme } from '@mui/material';
import MuiTableCell from '@mui/material/TableCell';
import withStyles from '@mui/styles/withStyles';
import { experimentalStyled as styled } from '@mui/material/styles';
import { GlobalSiteHealthStatus } from '../Model/HealthModels';
import CommonConstants from './CommonConstants';

export const StatusIcon = (status: string) => {
  if (status === CommonConstants.Online) {
    return <Chip color="success" label={status} size="small"></Chip>;
  } else if (status === CommonConstants.TimedOut) {
    return <Chip color="info" label={status} size="small"></Chip>;
  } else if (status === CommonConstants.Maintenance) {
    return <Chip color="warning" label={status} size="small"></Chip>;
  } else {
    return <Chip color="error" label={status} size="small"></Chip>;
  }
};

export const TableCell = withStyles({
  root: {
    borderBottom: 'none',
    whiteSpace: 'nowrap',
  },
})(MuiTableCell);

export const Item = styled(Paper)(({ theme }: { theme: Theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#091f2c' : '#fff',
  color: theme.palette.mode === 'dark' ? '#fff' : '#000',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  justifyContent: 'space-between',
  display: 'flex',
}));

export function customSort(
  a: GlobalSiteHealthStatus,
  b: GlobalSiteHealthStatus,
): number {
  if (a.Status === 'Online' && b.Status !== 'Online') {
    return 1;
  } else if (a.Status !== 'Online' && b.Status === 'Online') {
    return -1;
  } else {
    return 0;
  }
}
