import { Dictionary } from '../Components/Common/CommonModel';
import { Permissions } from '../Components/Layout/UIContainer/Global/GlobalState';
import { PermissionKeys } from './permissionConstants';

export const accessCheckFunctions: Record<
  string,
  (permissions: Dictionary<Permissions>, isProduction: boolean) => boolean
> = {
  hasLicenseModuleAccess: (permissions) =>
    permissions[PermissionKeys.LicenseReader] ||
    permissions[PermissionKeys.LicenseWriter]
      ? true
      : false,

  hasTenantRestartAccess: (permissions) =>
    permissions[PermissionKeys.RestartTenant] ? true : false,

  hasKeyVaultAccess: (permissions) =>
    permissions[PermissionKeys.SecureKeyReader] ||
    permissions[PermissionKeys.SecureKeyWriter]
      ? true
      : false,

  hasStorageAccountAccess: (permissions) =>
    permissions[PermissionKeys.StorageAccountReader] ||
    permissions[PermissionKeys.StorageAccountWriter]
      ? true
      : false,

  hasAccessControlAccess: (permissions) =>
    permissions[PermissionKeys.PermissionReader] ||
    permissions[PermissionKeys.PermissionWriter]
      ? true
      : false,

  hasModuleWriteAccess: (permissions) =>
    permissions[PermissionKeys.ModuleWriter] ||
    permissions[PermissionKeys.ModuleVariableWriter]
      ? true
      : false,

  hasKeyVaultWriteAccess: (permissions) =>
    permissions[PermissionKeys.SecureKeyWriter] ? true : false,

  hasStorageAccountWriteAccess: (permissions) =>
    permissions[PermissionKeys.StorageAccountWriter] ? true : false,

  hasEmailAccess: (permissions) =>
    permissions[PermissionKeys.EmailTemplateWriter] ||
    permissions[PermissionKeys.EmailTemplateReader]
      ? true
      : false,

  hasEmailSendAccess: (permissions) =>
    permissions[PermissionKeys.FarmReader] ||
    permissions[PermissionKeys.FarmWriter]
      ? true
      : false,

  hasAuditAccess: (permissions) =>
    permissions[PermissionKeys.AuditReader] ? true : false,
};
