import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { customerService } from '../CustomerService';
import { CustomerMenuProps } from '../Model/CustomerProps';
import { Menu, MenuItem } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { CustomerMenuItems, CustomerStateId } from '../Model/CustomerModel';
import { Dictionary } from '../../../Common/CommonModel';

import { PermissionsContext } from '../../UIContainer/PermissionsWrapper';
import PermissionConstants from '../../../Common/Constants/PermissionConstants';

const useStyles = makeStyles(() =>
  createStyles({
    menu: {
      padding: 0,
    },
  }),
);

const menuItems: CustomerMenuItems[] = [
  {
    id: 'edit',
    label: 'Edit Customer',
    linkTo: '/customer/{0}/edit',
    validationFunc: 'edit',
  },
  {
    id: 'modules',
    label: 'Modules',
    linkTo: '/customer/{0}/modules',
    validationFunc: 'modules',
  },
  {
    id: 'relay',
    label: 'Deploy relay',
    linkTo: '/customer/{0}/relay',
    validationFunc: 'collaboration',
  },
  {
    id: 'addtofarm',
    label: 'Add To Farm',
    linkTo: '/customer/{0}/createtenant',
    validationFunc: 'addtofarm',
  },
  {
    id: 'movetofarm',
    label: 'Move To Farm',
    linkTo: '/customer/{0}/movetenant',
    validationFunc: 'movetofarm',
  },
  {
    id: 'sites',
    label: 'Manage dss sites',
    linkTo: '/customer/{0}/sites',
    validationFunc: 'dsssites',
  },
  {
    id: 'trigger',
    label: 'Manage triggers',
    linkTo: '/customer/{0}/trigger',
    validationFunc: 'trigger',
  },
  {
    id: 'trustedclients',
    label: 'Manage trusted clients',
    linkTo: '/customer/{0}/trustedclients',
    validationFunc: 'trustedclients',
  },
  {
    id: 'clone',
    label: 'Clone Tenant',
    linkTo: '/customer/{0}/clone',
    validationFunc: 'clone',
  },
  {
    id: 'delete',
    label: 'Delete',
    linkTo: '/customer/{0}/delete',
    validationFunc: 'delete',
  },
  {
    id: 'oncalldrill',
    label: 'On-Call drill',
    linkTo: '/customer/{0}/oncalldrill',
    validationFunc: 'oncalldrill',
  },
  {
    id: 'restarttenant',
    label: 'Restart',
    linkTo: '/customer/{0}/restart',
    validationFunc: 'restart',
  },
  {
    id: 'ManageClientSecret',
    label: 'Manage ApiKey Secret',
    linkTo: '/customer/{0}/manageclientsecret',
    validationFunc: 'manageclientsecret',
  },
];

export default function CustomerMenu(props: Readonly<CustomerMenuProps>) {
  const { customerId, moduleIds } = props;
  const collaborationModuleId: string = 'collaboration';

  const classes = useStyles();
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const [menuState, setMenuState] = useState(false);
  const [hasActiveTenant, setHasActiveTenant] = useState(false);
  const [iscustomerOnFarm, setIsCustomerOnFarm] = useState(false);
  const permissions = useContext(PermissionsContext);
  const isAutoTestCustomer =
    props.stateId === CustomerStateId['Automatic test'];

  useEffect(() => {
    loadData();
  }, []);

  const closeCustomerMenu = () => {
    setMenuState(false);
    setAnchor(null);
  };

  const openCustomerMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuState(true);
    setAnchor(event.currentTarget);
  };
  const loadData = async () => {
    await loadActiveTenant();
    await CheckCustomerOnFarm();
  };
  const CheckCustomerOnFarm = async () => {
    const ispresent = await customerService.IsCustomerOnFarm(customerId);
    setIsCustomerOnFarm(ispresent);
  };

  const loadActiveTenant = async () => {
    try {
      const activeTenant = await customerService.getTenant(customerId, true);
      if (activeTenant.Items.length > 0) {
        setHasActiveTenant(true);
      }
    } catch {
      setHasActiveTenant(false);
    }
  };

  const hasCollaborationModule = () => {
    if (moduleIds) {
      return moduleIds.indexOf(collaborationModuleId) >= 0;
    }
    return false;
  };

  const hasTriggeReadPermission = () =>
    permissions[PermissionConstants.WriteTrigger] ||
    permissions[PermissionConstants.ReadTrigger]
      ? true
      : false;

  const hasCustomerWritePermission = () =>
    !!permissions[PermissionConstants.CustomerWriter];

  const hasCustomerDeleterPermission = () =>
    !!permissions[PermissionConstants.CustomerDeleter];

  const hasTenantRestartPermission = () =>
    !!permissions[PermissionConstants.RestartTenant];

  function executeValidationMethod(type: string) {
    let fn;
    const validations: Dictionary<() => boolean> = {};
    validations[collaborationModuleId] = hasCollaborationModule;
    validations['trigger'] = hasTriggeReadPermission;
    validations['edit'] = () => hasCustomerWritePermission();
    validations['delete'] = () => hasCustomerDeleterPermission();
    validations['addtofarm'] = () => !iscustomerOnFarm;
    validations['movetofarm'] = () => iscustomerOnFarm;
    validations['oncalldrill'] = () => isAutoTestCustomer && hasActiveTenant;
    validations['trustedclients'] = () => true;
    validations['restart'] = () =>
      hasActiveTenant && hasTenantRestartPermission();
    validations['manageclientsecret'] = () => hasActiveTenant;
    validations['clone'] = () => hasActiveTenant;
    validations['dsssites'] = () =>
      hasCustomerWritePermission() && hasActiveTenant;

    validations['modules'] = () => true;

    validations['default'] = () => false;

    if (validations[type]) {
      fn = validations[type];
    } else {
      fn = validations['default'];
    }
    return fn();
  }

  return (
    <div>
      <IconButton
        aria-label="More"
        aria-owns="long-menu"
        aria-haspopup="true"
        onClick={openCustomerMenu}
        className={classes.menu}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      {menuItems.some((menu) =>
        executeValidationMethod(menu.validationFunc),
      ) && (
        <Menu
          id="long-menu"
          anchorEl={anchor}
          open={menuState}
          onClose={closeCustomerMenu}
        >
          {menuItems
            .filter((menu) => executeValidationMethod(menu.validationFunc))
            .map((menu) => (
              <MenuItem
                key={menu.id}
                component={Link}
                to={menu.linkTo.format(customerId)}
              >
                {menu.label}
              </MenuItem>
            ))}
        </Menu>
      )}
    </div>
  );
}
