import React, { Component } from 'react';
import AddOnCallUser from './Components/AddUserDialog';
import OnCallOfTheWeek from './Components/OnCallOfTheWeek';
import OnCallUsersList from './Components/OnCallUsersList';
import ResponsibilityCalendar from './Components/ResponsibilityCalendar';
import { monitoringService } from './MonitoringService';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import { Box, Divider, Grid, Paper } from '@mui/material';

import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

import {
  DialogOptions,
  OnCallSubstituteUser,
  OnCallUser,
  UserSelection,
} from './Model/MonitoringModel';

import moment from 'moment';
import SubstituteUser from './Components/SubstituteUserDialog';
import { getNumberOfWeek, idToColor } from '../../../Helpers/UtilityMethods';

interface Props extends WithStyles<typeof styles> {}

interface MonitoringHomeState {
  onCallUsers: OnCallUser[];
  onCallUsersLoading: boolean;
  addUserDialogOpenState: boolean;
  subsUserDialogueState: boolean;
  onCallUserSelectionArray: UserSelection[];
  userWeekCounter: number;
  loadingOnCallUser: boolean;
  onCallUserOfTheWeek: OnCallUser;
  substituteUsers: OnCallSubstituteUser[];
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      flexGrow: 1,
    },
    fab: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    userListWidth: {
      width: 550,
    },
    paper: {
      padding: theme.spacing(2),
      height: '100%',
      color: theme.palette.text.secondary,
    },
  });

class MonitoringHome extends Component<Props, MonitoringHomeState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      onCallUsersLoading: true,
      onCallUsers: [],
      addUserDialogOpenState: false,
      subsUserDialogueState: false,
      onCallUserSelectionArray: [],
      userWeekCounter: 0,
      loadingOnCallUser: true,
      onCallUserOfTheWeek: {
        Id: '',
        Name: '',
        EmailAddress: '',
        PhoneNumber: '',
        Sequence: 0,
      },
      substituteUsers: [],
    };
  }

  componentDidMount(): void {
    this.getUsers();
    this.getOnCallUser();
  }

  getOnCallUser = () => {
    this.setState({ loadingOnCallUser: true });
    monitoringService
      .getOnCallOfTheWeek()
      .then((user) => {
        this.setState({ onCallUserOfTheWeek: user, loadingOnCallUser: false });
      })
      .catch((err) => console.log(err.Message));
  };

  getUsers = () => {
    this.setState({ onCallUsersLoading: true });

    monitoringService
      .getUsers()
      .then((users) => {
        this.setState({ onCallUsers: users });

        monitoringService.substituteUsers().then((substituteUsers) => {
          this.setState({
            substituteUsers: substituteUsers.Items,
            onCallUsersLoading: false,
          });

          this.buildUserSelectionArray(users, substituteUsers.Items);
        });
      })
      .catch((err) => console.log(err.message));
  };

  openAddUserDialog = () => {
    this.setState({ addUserDialogOpenState: true });
  };

  openSubsUserDialog = () => {
    this.setState({ subsUserDialogueState: true });
  };

  handleOnCallUserAddition = (
    option: DialogOptions,
    onCallUser: OnCallUser,
  ) => {
    if (option === DialogOptions.Ok) {
      const userSelectionForAddedUser = this.createOnCallUserSelection(
        onCallUser.Id,
        moment().isoWeekday(this.state.userWeekCounter).toDate(),
        moment()
          .isoWeekday(this.state.userWeekCounter + 6)
          .toDate(),
        idToColor(onCallUser.Id),
        onCallUser.Name,
      );
      this.setState((previousState) => ({
        onCallUsers: [...previousState.onCallUsers, onCallUser],
      }));
      this.setState((previousState) => ({
        onCallUserSelectionArray: [
          ...previousState.onCallUserSelectionArray,
          userSelectionForAddedUser,
        ],
      }));
      this.setState({ userWeekCounter: this.state.userWeekCounter + 7 });
    }
    this.setState({ addUserDialogOpenState: false });
  };

  handleOnCallUserSubstitution = (option: DialogOptions) => {
    if (option == DialogOptions.Ok) {
      this.getOnCallUser();
      this.getUsers();
    }
    this.setState({ subsUserDialogueState: false });
  };

  getSequenceWeeks = (length: number, currentWeek: number) => {
    return Array.from({ length: length }, (_, i) => currentWeek + i);
  };

  removeUserFromArray = (userId: string) => {
    this.setState({
      onCallUsers: this.state.onCallUsers.filter(function (user) {
        return user.Id !== userId;
      }),
    });
    this.buildUserSelectionArray(
      this.state.onCallUsers,
      this.state.substituteUsers,
    );
  };

  buildUserSelectionArray = (
    onCallUsers: OnCallUser[],
    substituteUsers: OnCallSubstituteUser[],
  ) => {
    let counter = 1;
    let userSelectionArray: UserSelection[] = [];

    onCallUsers.map((user) => {
      const subsUser = substituteUsers.find(
        (subuser) =>
          subuser.ActualUserId.toLocaleLowerCase() ===
            user.Id.toLocaleLowerCase() &&
          subuser.WeekNumber === moment().isoWeekday(counter).week(),
      );

      const userId = subsUser ? subsUser.SubstituteUserId : user.Id;
      const color = idToColor(userId);
      const name = subsUser
        ? onCallUsers.find((user) => user.Id == subsUser.SubstituteUserId)?.Name
        : user.Name;
      const userSelection: UserSelection = this.createOnCallUserSelection(
        userId,
        moment().isoWeekday(counter).toDate(),
        moment()
          .isoWeekday(counter + 6)
          .toDate(),
        color,
        name,
      );
      userSelectionArray = [...userSelectionArray, userSelection];
      counter = counter + 7;
    });
    this.setState({
      onCallUserSelectionArray: userSelectionArray,
      userWeekCounter: counter,
    });
  };

  createOnCallUserSelection = (
    userId: string,
    startDate: Date,
    endDate: Date,
    color: string,
    name: string | undefined,
  ): UserSelection => {
    const userSelection: UserSelection = {
      startDate: startDate,
      endDate: endDate,
      key: userId,
      showDateDisplay: false,
      autoFocus: false,
      color: color,
      name: name,
    };
    return userSelection;
  };

  render() {
    return (
      <React.Fragment>
        <OnCallOfTheWeek
          onCallUserOfTheWeek={this.state.onCallUserOfTheWeek}
          loadingOnCallUser={this.state.loadingOnCallUser}
        />
        <br />
        <Box sx={{ '& > :not(style)': { m: 1 } }}>
          <Tooltip title="Add new on call user">
            <Fab
              size="small"
              color="primary"
              aria-label="add"
              onClick={this.openAddUserDialog}
            >
              <AddIcon />
            </Fab>
          </Tooltip>
          <Tooltip title="Substitute on call user">
            <Fab
              size="small"
              color="primary"
              aria-label="add"
              onClick={this.openSubsUserDialog}
            >
              <PublishedWithChangesIcon />
            </Fab>
          </Tooltip>
        </Box>

        <br />
        <Divider />
        <br />
        {!this.state.onCallUsersLoading && (
          <>
            <AddOnCallUser
              open={this.state.addUserDialogOpenState}
              onClose={(options: DialogOptions, addedUser: OnCallUser) =>
                this.handleOnCallUserAddition(options, addedUser)
              }
            />

            <SubstituteUser
              open={this.state.subsUserDialogueState}
              onCallUsers={this.state.onCallUsers}
              weeks={this.getSequenceWeeks(
                this.state.onCallUsers.length,
                getNumberOfWeek(),
              )}
              onClose={(options: DialogOptions) => {
                this.handleOnCallUserSubstitution(options);
              }}
            />
          </>
        )}

        <Grid
          container
          spacing={6}
          alignItems={'flex-start'}
          direction={'row'}
          justifyContent={'flex-start'}
        >
          <Grid item className={this.props.classes.userListWidth}>
            <OnCallUsersList
              onCallUsers={this.state.onCallUsers}
              loading={this.state.onCallUsersLoading}
              removeUserFromArray={(userId: string) =>
                this.removeUserFromArray(userId)
              }
            />
          </Grid>
          <Grid item>
            <Paper className={this.props.classes.paper}>
              <ResponsibilityCalendar
                userSelectionArray={this.state.onCallUserSelectionArray}
              />
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(MonitoringHome);
