import React from 'react';
import { IconButton } from '@mui/material';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { GetActionLink } from '../../../../Common/CommonMethods';
import { useHistory, useLocation } from 'react-router-dom';

const EditActionLinkButton = (props: { routeComponents: string[] }) => {
  const history = useHistory();
  const location = useLocation();
  const link = GetActionLink(props.routeComponents);

  const handleClick = () => {
    const newPath = `${location.pathname}${link}`;
    history.push(newPath);
  };

  return (
    <IconButton onClick={handleClick}>
      <EditNoteIcon color="primary" />
    </IconButton>
  );
};

export default EditActionLinkButton;
