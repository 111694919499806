import React, { useState, useContext } from 'react';
import { Icon, CircularProgress, Tooltip, Fab } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { green } from '@mui/material/colors';

import UploadTriggers from './UploadTriggers';
import {
  TriggerOptionProps,
  TriggerConstants,
  TriggerCustomerContext,
} from '../Model';

import { ConfirmationDialog } from '../../../Common/Dialog';

const useStyles = makeStyles((theme) => ({
  flexRowContainer: {
    width: '100%',
    display: 'flex',
    maxWidth: '230px',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  firstFab: {
    position: 'absolute',
    bottom: theme.spacing(4),
    right: theme.spacing(3),
    zIndex: 2,
  },
  firstProgress: {
    color: green[500],
    position: 'absolute',
    bottom: theme.spacing(3.2),
    right: theme.spacing(2.2),
    zIndex: 2,
  },
  secondFab: {
    position: 'absolute',
    bottom: theme.spacing(13),
    right: theme.spacing(3),
    zIndex: 2,
  },
  secondProgress: {
    color: green[500],
    position: 'absolute',
    bottom: theme.spacing(12.2),
    right: theme.spacing(2.2),
    zIndex: 2,
  },
  fourthFab: {
    position: 'absolute',
    bottom: theme.spacing(31),
    right: theme.spacing(3),
    zIndex: 2,
  },
  thirdFab: {
    position: 'absolute',
    bottom: theme.spacing(22),
    right: theme.spacing(3),
    zIndex: 2,
  },
}));

export default function TriggerOptions(props: TriggerOptionProps) {
  const classes = useStyles();
  const {
    saveTriggerCallback,
    deployTriggerCallback,
    reloadTriggersCallback,
    resetTriggerCallback,
  } = props;
  const [saving, setSaving] = useState(false);
  const [deploying, setDeploying] = useState(false);

  const [showUploadWindow, setShowUploadWindow] = useState(false);
  const [showConfirmation, setShowConfirmationDialog] = useState(false);
  const [confirmationMessage, setConfirmationDialogMessage] = useState('');

  const { ContentChanged } = useContext(TriggerCustomerContext);

  const saveTrigger = async () => {
    setSaving(true);
    await saveTriggerCallback();
    setSaving(false);
  };

  const deployTrigger = async () => {
    setDeploying(true);
    await deployTriggerCallback();
    setDeploying(false);
  };

  const handleReset = () => {
    setConfirmationDialogMessage(
      TriggerConstants.TriggerResetConfirmationMessage,
    );
    setShowConfirmationDialog(true);
  };

  const setConfirmationValuesToDefault = () => {
    setShowConfirmationDialog(false);
    setConfirmationDialogMessage('');
  };

  const reset = () => {
    resetTriggerCallback();
    setConfirmationValuesToDefault();
  };

  const openUploadWindow = () => {
    setShowUploadWindow(true);
  };

  const closeUploadWindow = () => {
    setShowUploadWindow(false);
  };

  const onUploadComplete = async () => {
    setShowUploadWindow(false);
    await reloadTriggersCallback();
  };

  return (
    <div className={classes.flexRowContainer}>
      <Tooltip title={TriggerConstants.DeployButtonTooltip}>
        <Fab
          className={classes.firstFab}
          aria-label="deploy"
          disabled={saving || deploying}
          color="primary"
          onClick={deployTrigger}
        >
          <Icon>backup</Icon>
        </Fab>
      </Tooltip>
      {deploying && (
        <CircularProgress size={68} className={classes.firstProgress} />
      )}
      <Tooltip title={TriggerConstants.SaveButtonTooltip}>
        <Fab
          className={classes.secondFab}
          aria-label="save"
          disabled={!ContentChanged || saving || deploying}
          color="primary"
          onClick={saveTrigger}
        >
          <Icon>save</Icon>
        </Fab>
      </Tooltip>
      {saving && (
        <CircularProgress size={68} className={classes.secondProgress} />
      )}
      <Tooltip title={TriggerConstants.ResetButtonTooltip}>
        <Fab
          className={classes.fourthFab}
          aria-label="reset"
          disabled={saving || deploying}
          color="secondary"
          onClick={handleReset}
        >
          <Icon>autorenew</Icon>
        </Fab>
      </Tooltip>
      <Tooltip title={TriggerConstants.UploadButtonTooltip}>
        <Fab
          className={classes.thirdFab}
          disabled={saving || deploying}
          aria-label="upload"
          color="primary"
          onClick={openUploadWindow}
        >
          <Icon>publish</Icon>
        </Fab>
      </Tooltip>
      <ConfirmationDialog
        open={showConfirmation}
        message={confirmationMessage}
        onConfirmHandler={reset}
        onCancelHandler={setConfirmationValuesToDefault}
        onCloseHandler={setConfirmationValuesToDefault}
      />
      <UploadTriggers
        open={showUploadWindow}
        onClose={closeUploadWindow}
        onUploaded={onUploadComplete}
      />
    </div>
  );
}
